import React from 'react'

const Dengue_Fever_Treatment_In_Guntur = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Dengue Fever</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Dengue Fever</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Dengue fever is a mosquito-borne viral infection that poses a significant health risk in many parts of the world. This blog post aims to provide valuable information about Dengue fever, covering its symptoms, prevention strategies, and available treatments.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is Dengue Fever?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                            Dengue fever is caused by the Dengue virus, transmitted to humans through the bites of infected female mosquitoes, primarily the Aedes aegypti mosquito.
                        </p>
                        <p><b>Global Impact:</b></p>
                        <p style={{ textAlign: 'justify' }}>
                            Dengue fever is a global health concern, affecting millions of people each year. It is prevalent in tropical and subtropical regions, including parts of Asia, the Americas, Africa, and the Pacific.
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='dengue.jpg' alt=''></img>
                    </div>
                </div>

                {/* <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms Of Fever Include:</span>
        </h4 > */}


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms of Dengue Fever</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}>
                    Early Symptoms:
                </p>
                <p><b>Sudden Onset: </b>Dengue symptoms often appear suddenly.</p>
                <p><b>High Fever: </b>Severe fever is a common early symptom.</p>
                <p><b>Severe Headache: </b>Intense headaches are a characteristic sign.</p>
                <p><b>Joint and Muscle Pain: </b>Dengue is often referred to as "breakbone fever" due to the severe pain in joints and muscles.</p>
                <p>Severe Symptoms (Dengue Hemorrhagic Fever and Dengue Shock Syndrome):</p>
                <p>Bleeding: Uncontrolled bleeding can occur.</p>
                <p>Organ Failure: Severe cases may lead to organ failure.</p>
                <p>Shock: Dengue shock syndrome can cause a drop in blood pressure.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Prevention Strategies</span>
                </h4 >
                <p><b>1. Mosquito Control:</b></p>
                <ul>
                    <li>Eliminate standing water where mosquitoes breed.</li>
                    <li>Use mosquito nets and screens.</li>
                    <li>Wear long-sleeved clothing and use insect repellent.</li>
                </ul>
                <p><b>2. Community Engagement:</b></p>
                <ul>
                    <li>Raise awareness about the importance of mosquito control.</li>
                    <li>Encourage community efforts in cleaning and maintaining mosquito-free environments.</li>
                </ul>
                <p><b>3. Personal Protection:</b></p>
                <ul>
                    <li>Use mosquito repellent on exposed skin.</li>
                    <li>Avoid outdoor activities during peak mosquito activity times.</li>
                    
                </ul>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Treatment Options</span>
                </h4>
                <p><b>1. Supportive Care:</b></p>
                <ul>
                    <li>Stay hydrated with fluids.</li>
                    <li>Rest to aid recovery.</li>
                    <li>Use pain relievers for fever and pain management.</li>
                </ul>
                <p><b>2. Hospitalization (for severe cases):</b></p>
                <ul>
                    <li>Intravenous fluids to manage dehydration.</li>
                    <li>Blood transfusions in cases of severe bleeding.</li>
                    <li>Monitoring for organ dysfunction.</li>
                </ul>
                <p><b>3. No Specific Antiviral Treatment:</b></p>
                <ul>
                    <li>Dengue is a viral infection, and there is no specific antiviral medication.</li>
                    <li>Treatment focuses on relieving symptoms and supporting the body's natural healing processes.</li>
                    
                </ul>
              
            </div>
        </>
    )
}

export default Dengue_Fever_Treatment_In_Guntur