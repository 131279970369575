import React from 'react'

const Dr_Bharathi = () => {
  return (
    <>
    <div style={{height:'150px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '40px', display: 'flex',backgroundColor:"rgb(245, 41, 41)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>ABOUT US</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>   
          </ul>
        </div>
      </div>
      <div class="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div class="row">
          <div class="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Dr NELLURI BHARATHI</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
            Hands with more than two 2 decades of experience. Dr Bharathi M.S, (OBG) is a Postgraduate from renowned institute, Manipal Academy of Higher Education and has risk Pregnancy management. She also has extended her support by developing infertility and fetal medicine units.
            </p>
            <p style={{ textAlign: 'justify' }}>
            Engagement beyond hospital visits. Pregnant women are continuously and actively engaged with a novel patient engagement program by the pregnancy care coordinators under the supervision of Dr. Bharathi. In addition to regular weekly updates and exercise / nutrition tips, they are continuously monitored by the hospital team.
            </p>
          
          </div>
          <div  style={{ textAlign: 'center' }} class="col-lg-5 servicesimg">
            <img className="mt-1 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px',borderRadius:'15px' ,width:'350px',boxShadow:'grey' }} id="ServicesImG" src='docto2.png' alt=''></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dr_Bharathi