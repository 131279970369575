import React from 'react'

const Gestational = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>GESTATIONAL DIABETES</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>GESTATIONAL DIABETES</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is gestational diabetes?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        Gestational diabetes (GD) is a type of diabetes that develops in pregnancy when blood sugar levels get too high.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Developing GD doesn’t mean you already had diabetes before you got pregnant. The condition appears because of the pregnancy. People with Type 1 and Type 2 diabetes have their own, separate challenges when it comes to pregnancy.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>When will gestational diabetes (GD) appears in pregnancy?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                            GD usually appears during the middle of pregnancy, between 24 and 28 weeks. Often gestational diabetes can be managed through eating healthy foods and regular exercise. Sometimes a woman with gestational diabetes must also take insulin and related medication.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Midsection-Of-Pregnant-Woman-Checking-Diabetes_G_1155170325.png' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What causes gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Gestational diabetes comes from hormonal changes and the way our bodies convert food into energy.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    A hormone called insulin breaks down the glucose (sugar) from food and delivers it to our cells. Insulin keeps the level of glucose in our blood at a healthy level. But if insulin doesn’t work right or we don’t have enough of it, sugar builds up in the blood and leads to diabetes.
                </p>
                <p style={{ textAlign: 'justify' }}>
                    During pregnancy, hormones can interfere with the way insulin works. It may not regulate your blood sugar levels like it’s supposed to, which can lead to gestational diabetes. Genes and having overweight (a BMI greater than 25) may also play a role.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Who is at risk for gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Gestational diabetes can develop in anyone during pregnancy. </p>
                <p style={{ textAlign: 'justify' }}>Factors that may increase your chances of GD include:</p>
                <ul>
                    <li>Heart disease.</li>
                    <li>High blood pressure.</li>
                    <li>Inactivity.</li>
                    <li>Obesity.</li>
                    <li>Personal or family history of GD.</li>
                    <li>Polycystic ovary syndrome (PCOS).</li>
                    <li>Previous birth of a baby weighing nine pounds (lb) or more.</li>
                    <li>Prediabetes (history of higher-than-normal blood glucose).</li>

                </ul>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What are the warning signs of gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Gestational diabetes doesn’t usually cause any symptoms. But some people experience:</p>
                <ul>
                    <li>Frequent urination.</li>
                    <li>Nausea.</li>
                    <li>Thirst.</li>
                    <li>Tiredness.</li>
                </ul>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What are the warning signs of gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>There are usually no warning signs of gestational diabetes. Symptoms are mild and often go unnoticed until you’re tested for diabetes in the second trimester of pregnancy.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Is gestational diabetes caused by diet?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Diet can be a contributing factor to developing gestational diabetes, but your diet doesn’t cause diabetes. Gestational diabetes happens when the hormones from your placenta block your ability to use or make insulin. Insulin helps your body maintain the right amount of glucose in your blood. When you have diabetes, you end up with too much sugar in your blood.</p>
                <p style={{ textAlign: 'justify' }}>You can help manage gestational diabetes by eating healthy foods that don’t cause your blood sugar to rise to unsafe levels.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Why is it important to treat gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>If left untreated, gestational diabetes can lead to complications for both you and the fetus.</p>
                <p style={{ textAlign: 'justify' }}>Gestational diabetes increases your risk of:</p>
                <ul>
                    <li>Cesarean birth (C-section) if the fetus gets too big.</li>
                    <li>Preeclampsia (high blood pressure during pregnancy).</li>
                    <li>Type 2 diabetes.</li>

                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How should I change my diet for gestational diabetes?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>You may need to adjust your diet to keep gestational diabetes under control. Try to:</p>
                <ul>
                    <li>Avoid junk food, processed foods and sugary drinks.</li>
                    <li>Choose a healthy balance of proteins, carbohydrates, fiber and fat.</li>
                    <li>Eat smaller meals more often.</li>
                    <li>Schedule your meals at the same time each day.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How can I exercise safely during pregnancy?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Exercise helps your body use more glucose, which may lower your blood sugar. If you have gestational diabetes, talk to your healthcare provider about creating a safe exercise plan. Your exercise plan should be tailored to your unique needs, including your:</p>
                <ul>
                    <li>Age.</li>
                    <li>Fitness level before pregnancy.</li>
                    <li>Overall health.</li>
                    <li>Weight.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Can you get rid of gestational diabetes while pregnant?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>No, you can’t get rid of gestational diabetes once you have it. After you’re diagnosed with the condition, you’re not tested again for diabetes until after your baby is born. It’s recommended to get tested for diabetes within 12 weeks after your baby is born.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How can gestational diabetes be prevented?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>It’s not entirely preventable, but you can take steps to reduce your risk. Eating a balanced diet and getting regular exercise before and during your pregnancy is the best way to reduce your risk of developing gestational diabetes.</p>
            </div >
        </>
    )
}

export default Gestational