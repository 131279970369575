import React from 'react'
import { Link } from 'react-router-dom'

const smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Smart ICU Launched At Chilakaluripeta</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className='container mt-4'>
        <h3 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Smart ICU Launched At Chilakaluripeta Andhra Pradhesh By Health Minister Vidadala Rajini & Dr Kalyan Chakravarthy Koganti</span>
        </h3>
        <div className='row' >
          <div className='col-lg-4 col-sm-12 mt-2'>
            <img src='WhatsApp Image 2023-10-03 at 10.00.38 AM.jpeg' alt='' style={{ width: '100%' }}></img>
          </div>
          <div className='col-lg-4 col-sm-12 mt-2'>
            <img src='WhatsApp Image 2023-10-03 at 10.00.39 AM (1).jpeg' alt='' style={{ width: '100%' }}></img>
          </div>
          <div className='col-lg-4 col-sm-12 mt-2'>
            <img src='WhatsApp Image 2023-10-03 at 10.00.39 AM.jpeg' alt=' ' style={{ width: '100%' }}></img>
          </div>
        </div>
      </div>

      <div className='container mt-4'>
        <img src='TOI_logo.webp' alt='' style={{ width: '30px', height: '30px' }}></img>
        <a className='p-3'><Link to='https://timesofindia.indiatimes.com/city/vijayawada/smart-icu-launched-at-phc-in-andhra-pradesh/articleshow/104070686.cms?from=mdr'> Aritical In Times Of India</Link></a>
      </div>
      <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">

            <p style={{ textAlign: 'justify' }}>
              {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                  Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
              In a groundbreaking collaboration between the government of Guntur and SHRI Hospital, the healthcare sector is witnessing a transformative change with the introduction of a Live ICU at the Primary Healthcare Centre (PHC) in Chilakaluripet. The brainchild of Dr. Kalyan Chakravarthy, Director of SHRI Hospital Group, this cutting-edge initiative promises to bring advanced healthcare facilities to rural areas, saving critical patients' lives and reducing the need for transfers to referral hospitals.
            </p>

            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>A Healthcare Innovation with Heart</span>
            </h4 >

            <p style={{ textAlign: 'justify' }}>The Live ICU project is spearheaded by Dr. Kalyan Chakravarthy, a visionary in the field of healthcare technology. This project, launched in the hometown of Health Minister V. Rajani, is a testament to the commitment of both government and private sectors to bring quality healthcare to rural communities.</p>

            {/* <p style={{ textAlign: 'justify' }}>
            Started Center for Infectious Diseases in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
            </p>
            <p style={{ textAlign: 'justify' }}>
            He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
            </p> */}
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
            <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='timesOfIndia.jpeg' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Technology That Saves Lives</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>At the heart of this initiative is a novel integration of technology, automation, and collaboration. As soon as a patient enters the emergency room, their health data is seamlessly captured by integrated technology. This data is then continuously streamed to higher centers, where specialists, including intensivists and super-specialists, can evaluate the patient's condition in real-time and provide live suggestions to local doctors. This process eliminates the need to transfer critical patients, saving invaluable time in emergencies.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>AI and Machine Learning: A Game-Changer</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>One of the most exciting aspects of this project is the use of AI and machine learning. Dr. Kalyan Chakravarthy and his team have developed a deterioration index model, which helps identify patients whose conditions are deteriorating earlier than can be recognized through regular clinical evaluations. This innovation has the potential to revolutionize healthcare, ensuring that critical patients receive timely care, even in remote rural healthcare institutions.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Round-the-Clock Expertise</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>The availability of intensivists and super-specialists around the clock is a game-changer. It ensures that critical patients receive timely care, potentially saving countless lives. Moreover, this initiative empowers rural doctors to better manage patients and make informed decisions.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>A Cost-Effective Solution</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>The Live ICU project, initially supporting four emergency beds, is a cost-effective solution. With an annual cost of 25 lakhs, it includes establishment expenses, making advanced healthcare accessible in rural areas.</p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>A Vision for the Future</span>
            </h4 >
           <p style={{ textAlign: 'justify' }}>As critical patients are often transferred from rural health centers to referral hospitals, valuable time is lost during the journey, endangering lives. The Smart ICU project, driven by AI and championed by Dr. Kalyan Chakravarthy, is set to change this reality. It promises to provide initial resuscitation and critical patient care, regardless of their location.</p>
           <p style={{ textAlign: 'justify' }}>In conclusion, the Live ICU project in Guntur, led by the visionary Dr. Kalyan Chakravarthy, represents a significant leap forward in the healthcare sector. It exemplifies the power of collaboration between the government and private sectors, backed by innovative technology, to bring advanced healthcare facilities to rural communities. This initiative is poised to save lives, reduce healthcare costs, and set a new standard for healthcare excellence.</p>
        
      </div>

    </>
  )
}

export default smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh