import React from 'react'

const First_Trimester = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>First Trimester</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>First Trimester</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is the first trimester of pregnancy?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        The first trimester begins before you’re pregnant. It starts on the first day of your last menstrual period and goes until the 13th week of pregnancy.
                        </p>
                       
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}> What can I expect in my first trimester?</span>
                        </h4 >
                        <p>Your first trimester of pregnancy is full of many physical and emotional changes. It can be a very overwhelming time, and your mind may be racing with questions. Plus, your hormones are in overdrive. In fact, your body produces more estrogen during one pregnancy than it does during your entire life when you’re not pregnant. This surge in hormones can cause some unpleasant pregnancy symptoms. You may find yourself feeling moody, bloated and tired. While you may not see a prominent baby bump yet, your uterus is expanding and your blood volume is increasing.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="1st-trimester1.png" alt=''></img>
                    </div>
                </div>
               <p>It’s OK to feel both excited and nervous. Talking to your friends, partner or a healthcare provider may help you feel better as you navigate your pregnancy journey.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Why is the first trimester of pregnancy so critical?</span>
                        </h4 >
                <p style={{ textAlign: 'justify' }}>
                The first trimester is so important because most of the fetus’s major organs and body systems are developing. Toxins, harmful substances and infection can severely damage a fetus’s growth and development during this time. It could increase your baby’s risk of being born with a congenital disorder.
                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>When should I call my pregnancy care provider during the first trimester?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                   Call your provider right away if you have:
                </p>

                <ul>
                    <li>A fever higher than 100.4 degrees Fahrenheit.</li>
                    <li>Heavy bleeding or unusual vaginal discharge.</li>
                    <li>Severe cramping in your belly, arms or legs or abdominal pain.</li>
                    <li>Persistent vomiting and/or diarrhea.</li>
                    <li>Fainting spells or dizziness.</li>
                    <li>Swelling in your hands, fingers or face.</li>
                    <li>Blurred vision or spots before your eyes.</li>
                </ul>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Is it normal to bleed during the first trimester of pregnancy?</span>
                </h4 >

                <p>Light bleeding or spotting during pregnancy is usually OK in the first few weeks of pregnancy. Some people experience implantation bleeding (when a fertilized egg burrows unto your uterine lining). Call your pregnancy care provider if you’re bleeding heavily or the bleeding lasts more than one day.</p>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Can I drink caffeine during pregnancy?</span>
                </h4 >
                <p>Most healthcare providers recommend limiting caffeine consumption to under 200 milligrams per day during pregnancy. That’s about 12 ounces of coffee or three cans of Mtn Dew®. This is because a fetus can’t metabolize caffeine so it can build up in their body and cause complications.</p>
               

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How does the fetus develop in the first trimester of pregnancy?</span>
                </h4 >
                <p>Several developments occur in the first trimester. Although you can’t see it happening, there’s a lot going on inside your body after sperm fertilizes an egg.</p>

                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Weeks one to four of pregnancy</span>
                </h4 >
                <p>During the first month of pregnancy, several important structures form. These structures are a tiny clump of cells, but will grow to become the amniotic sac, placenta and umbilical cord. A tube that becomes the fetus’s brain and spinal cord forms, as well as its circulatory system. A face, circles for eyes and the beginning of a mouth take shape.</p>
                <p>The embryo is about a quarter-inch inch long — smaller than a grain of rice.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Weeks five to eight of pregnancy</span>
                </h4 >
                <p>Several major organs begin to develop during the sixth week of pregnancy including the fetal lungs, heart, ears, arms and legs. Bones begin to replace tissue. Its head is large in proportion to the rest of its body, but it look more human now. The fetus has a distinct mouth, nose and face. Some providers do an early ultrasound to confirm a heartbeat during this time.</p>
                <p>By the end of the eighth week of pregnancy, the embryo becomes a fetus. It’s about 1 inch long or the size of a raspberry.</p>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Weeks nine to 12 of pregnancy</span>
                </h4 >
                <p>Towards the end of your first trimester, the fetus will have toes, fingers and nails. It will start to move by opening and closings its hands and mouth. The fetus’s urinary and digestive systems are also fully functioning. At around 12 weeks of pregnancy, your provider can listen to the fetal heart using a Doppler ultrasound. It also has either a vagina or a penis at this point (though your provider can’t see it on an ultrasound).</p>
                <p>By the end of the 12th week of pregnancy, the fetus is between 3 and 4 inches long — about the size of a plum. It weighs about 1 ounce.</p>
           </div >
        </>
    )
}

export default First_Trimester