import React from 'react'

const Dr_Kalyan_Chakravarthy_Koganti = () => {
  return (
    <>
      <div style={{ height: '100px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>ABOUT US</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>

          </ul>
        </div>
      </div>


      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Dr KALYAN CHAKRAVARTHY KOGANTI</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education.
              Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state.

            </p>
            <p style={{ textAlign: 'justify' }}>
              Started Center for Infectious Diseases at Help Hospital in Guntur with state of the art Micobiology lab supported with automated culture systems, PCR and CB-NAAT technologies.
            </p>
            <p style={{ textAlign: 'justify' }}>
              He is also pioneer in initiating first of its kind integrated health care with remote patient monitoring .Health ATM is his brain child that helps to monitor the patients 24/7 even from home or any other remote location.
            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='1692169279105.jpg' alt=''></img>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dr_Kalyan_Chakravarthy_Koganti