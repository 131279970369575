import React from 'react'

const TDAP_VACCINE_DURING_PREGNANCY = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>TDAP VACCINE DURING PREGNANCY</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is a Tdap vaccine?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Tdap (tetanus toxoid, reduced diphtheria toxoid, and acellular pertussis) is a vaccine that protects against three serious diseases:
                        </p>
                        <ul style={{ textAlign: 'justify' }}>
                            <li>Whooping cough (pertussis) is a highly contagious disease that causes severe coughing. Newborns and babies are at high risk of severe whooping cough, which can be life-threatening.</li>
                            <li>Tetanus bacteria can enter the body through a break in the skin. Tetanus can paralyze the muscles that help you breathe. In some cases, tetanus can cause death.</li>
                            <li>Diphtheria can restrict breathing and cause death.</li>
                        </ul>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Tdap vaccine in pregnancy:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            All pregnant women should get the Tdap vaccine during each pregnancy. The vaccine helps your body make antibodies to protect you from disease. These antibodies pass to your fetus and can protect your newborn until he or she can get the Tdap vaccine at 2 months old
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '250px', borderRadius: '15px', width: '80%', boxShadow: 'grey' }} id="ServicesImG" src='Tdap-vaccine.png' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>When to take Tdap vaccine in pregnancy?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Tdap during pregnancy provides the best protection for mother and infant. Optimal timing is between 27 and 36 weeks gestation (preferably during the earlier part of this period) to maximize the maternal antibody response and passive antibody transfer to the infant.
                        </p>
                <p style={{ textAlign: 'justify' }}>
                    Pregnant women should receive Tdap anytime during pregnancy if it is indicated for wound care or during a community pertussis outbreak.
                </p>

                <p style={{ textAlign: 'justify' }}>
                    If Tdap is administered earlier in pregnancy, it should not be repeated between 27 and 36 weeks gestation
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How many doses are recommended?</span>
                </h4>
                <ul>
                    <li>Only one dose is recommended during each pregnancy.</li>
                </ul>
            </div>
        </>
    )
}

export default TDAP_VACCINE_DURING_PREGNANCY