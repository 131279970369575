import React from 'react'

const Post_partum_care = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Post-partum care</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Post-partum care</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is postpartum period?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                            The postpartum period begins soon after the delivery of the baby and usually lasts six to eight weeks and ends when the mother’s body has nearly returned to its pre-pregnant state. The postpartum period for a woman and her newborn is very important for both short-term and long-term health and well-being.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Postpartum nutrition</span>
                        </h4 >
                        <p>
                            A good way to understand postpartum nutrition is understanding what it is and why it’s important. After you safely deliver your baby, it’s time to help your body recover and heal while taking care of your little one. Whether you’re breastfeeding or not, you will still need a lot of energy and the right nutrients to make recovery quick and easy.
                        </p>
                       
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Foods to be taken:</span>
                        </h4 >
                        <ul>
                            <li>Vegetables</li>
                            <li>Fruits</li>
                            <li>Lean proteins</li>
                            <li>Whole grains</li>
                            <li>Dairy products</li>
                            <li>Plenty of liquids</li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="post_partumCareImage.png" alt=''></img>
                    </div>
                </div>
                <p>
                Apart from these, there are some Indian foods and spices that are considered superfoods for women, just after delivery. These include moringa leaves, sprouted grains, bottle gourd, dry fruits, fenugreek seeds, ajwain, turmeric, garlic, sesame seeds, edible gum or gondh, panjeeri, ginger and cumin seeds. As these are a part of everyday diet, you can also include them easily in your diet without much effort.
                </p>
              
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Foods to be avoided:</span>
                </h4 >
              <ul>
                <li>Alcohol</li>
                <li>Caffeine</li>
                <li>Oily and spicy foods</li>
                <li>Allergic foods</li>
               
              </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Postnatal exercise:</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                Regular exercise has numerous health benefits, all of which apply equally to the new mother as at any other stage of life. These benefits include assistance with weight loss, increased aerobic fitness, social interaction and psychological wellbeing. Exercise after giving birth can also hasten recovery, and assist with muscle strength and toning.
                                </p>
                                
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>The benefits of postnatal exercise</span>
                </h4 >
                <p>The benefits of postnatal exercise</p>
                <p>It can give many benefits such as:</p>
                <ul>
                    <li>help restore muscle strength and firm up your body</li>
                    <li>make you less tired because it raises your energy level and improves your sense of wellbeing</li>
                    <li>promote weight loss</li>
                    <li>improve your cardiovascular fitness and restore muscle strength</li>
                    <li>condition your abdominal muscles</li>
                    <li>improve your mood, relieve stress and help prevent postnatal depression.</li>
                </ul>
            </div >
        </>
    )
}

export default Post_partum_care