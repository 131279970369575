import React from 'react'

const Blood_Pressure = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>High BP Treatment In Guntur</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>

          </ul>
        </div>
      </div>


      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Blood Pressure</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Hypertension can be defined as chronic or transitory elevation of the blood pressure in the arteries. This elevation may be caused due to cardiovascular damage. When the blood pressure is reached above 140/90, it may lead to hypertension. High BP treatment in Guntur by best doctor for high BP has been associated with reductions in stroke, heart attack, and heart failure.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms Of High Blood Pressure</span>
            </h4 >
            <ul>
              <li>Severe headache.</li>
              <li>Fatigue or confusion.</li>
              <li>Chest pain.</li>
              <li>Vision issues.</li>
              <li>Symptoms Of Fever Include:</li>
              <li>Difficulty to breathe.</li>
              <li>Abnormal heartbeat.</li>
              <li>Pounding in your chest, neck, or ears.</li>
              <li>Blood in the urine.</li>
            </ul>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='blood_pressure.jpg' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>
            Four Stages Of Hypertension:</span>
        </h4>
        <p style={{ textAlign: 'justify' }}>
          STAGE 1: Prehypertension (120/80 to 139/89).
        </p>
        <p style={{ textAlign: 'justify' }}>
          STAGE 2: Mild Hypertension (140/90 to 159/99). Take proper rest.
        </p>
        <p style={{ textAlign: 'justify' }}>
          STAGE 3: Moderate Hypertension (160/100 to 179/109).
        </p>
        <p style={{ textAlign: 'justify' }}>
          STAGE 4: Severe Hypertension (180/110 or higher).
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>
          Treatment Includes</span>
        </h4>
        <p style={{ textAlign: 'justify' }}>Intake of healthier diet with less salt. Regular Exercise and taking medication can help to lower blood pressure.</p>
        <p style={{ textAlign: 'justify' }}>
        Stress management.
        </p>
        <p style={{ textAlign: 'justify' }}>
        Quit smoking.
        </p>
        <p style={{ textAlign: 'justify' }}>
        Low sodium diet.
        </p>
        <p style={{ textAlign: 'justify' }}>
        Proper medications
        </p>
        <p style={{ textAlign: 'justify' }}>
        Best High BP Treatment in Guntur with medication is recommended at Best Hypertension clinic in Guntur to lower blood pressure to less than 130/80 in people older than age 65 and those with risk factors such as diabetes and high cholesterol.
        </p>
      </div>
    </>
  )
}

export default Blood_Pressure