import React from 'react'

const Insurance_Available_In_Hospital = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>అన్ని ప్రైవేట్ ఇన్సూరెన్స్ కంపెనీల సౌకర్యం కలదు</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>
            {/* <div className='container mt-5'>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Insurance</span>
                </h1>
                <div className='row text-center'>
                    <div className="col-lg-3">
                        <img style={{ height: '80px', width: '130px', }} src="ABH.png" alt="im1" />
                    </div>
                    <div className="col-lg-3">
                        <img style={{ height: '80px', width: '130px' }} src="FGI.png" alt="im2" />
                    </div>

                    <div className="col-lg-3">
                        <img style={{ height: '100px', width: '130px' }} src="FHPL.png" alt="im3" />
                    </div>

                    <div className="col-lg-3">
                        <img style={{ height: '80px', width: '130px' }} src="GD.png" alt="im4" />
                    </div>
                </div>

                <div className="row text-center mt-5">
                        <div className="col-lg-3">
                            <img style={{ height: '80px', width: '150px'}} src="GH.png" alt="im5" />
                        </div>
                        <div className="col-lg-3">
                            <img style={{ height: '80px', width: '100px' }} src="HI.png" alt="im6" />
                        </div>

                        <div className="col-lg-3">
                            <img style={{ height: '80px', width: '100px' }} src="ICICI.png" alt="im7" />
                        </div>

                        <div className="col-lg-3">
                            <img style={{ height: '80px', width: '100px' }} src="IFFCO.png" alt="im8" />
                        </div>
                    </div>

                    <div className="row text-center mt-5">
                        <div className="col-lg-4">
                            <img style={{ height: '80px', width: '130px' }} src="MA.png" alt="im9" />
                        </div>
                        <div className="col-lg-4">
                            <img style={{ height: '100px', width: '200px' }} src="MC.png" alt="im10" />
                        </div>


                        <div className="col-lg-4 ">
                            <img style={{ height: '100px', width: '130px' }} src="MHI.png" alt="im11" />
                        </div>
                    </div>

                    <div className="row text-center mt-5">
                        <div className="col-lg-3 ">
                            <img style={{ height: '90px', width: '90px' }} src="NABH.png" alt="im12" />
                        </div>
                        <div className="col-lg-3">
                            <img style={{ height: '80px', width: '120px' }} src="RGI.png" alt="im13" />
                        </div>

                        <div className="col-lg-3">
                            <img style={{ height: '100px', width: '150px' }} src="RHI.png" alt="im14" />
                        </div>

                        <div className="col-lg-3">
                            <img style={{ height: '100px', width: '140px' }} src="STAR.png" alt="im15" />
                        </div>
                    </div>

                    <div className="row text-center mt-5">
                        <div className="col-lg-4">
                            <img style={{ height: '80px', width: '180px' }} src="SW.png" alt="im16" />
                        </div>
                        <div className="col-lg-4">
                            <img style={{ height: '80px', width: '130px' }} src="UHC.png" alt="im17" />
                        </div>

                        <div className="col-lg-4">
                            <img style={{ height: '80px', width: '130px' }} src="VH.png" alt="im18" />
                        </div>


                    </div>
            </div> */}
            <div className='container'>
                <img src='insuranceImage.jpeg' alt='insurance Image' style={{width:'100%'}}></img>
            </div>

           
        </>
    )
}

export default Insurance_Available_In_Hospital