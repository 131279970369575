import React from 'react'

const Mother_News = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Mother News</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        {/* <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Mother News</span>
                        </h1>
                        <p style={{textAlign:'justify'}}><b>Dear </b></p> */}
                        <p style={{textAlign:'justify'}}><b>At the outset, we thank you for believing in HELP HOSPITAL as your pregnancy care partner. We would like to give you the best healthy experience during your pregnancy.</b></p>
                        <p style={{textAlign:'justify'}}><b>In conjunction, we are starting a novel engagement program and will send you the weekly updates, dietary and exercise recommendations. You can forward your doubts/questions here and a coordinator will regularly update them in liaison with Dr. Bharathi.</b></p>
                        <p style={{textAlign:'justify'}}><b>Wish you a happy pregnancy.</b></p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>You are 4 weeks pregnant </span>
                        </h4 >
                        <p style={{textAlign:'justify'}}>Conception usually takes place about 2 weeks after your last period, around the time you release an egg (ovulate). In the first 4 weeks of pregnancy, you probably will not notice any symptoms. The first thing you may notice is that your period does not arrive. In the first few weeks of pregnancy you may have a bleed similar to light period with some spotting. This is called implantation bleeding. </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '400px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="pregnant-young-woman-white-t-shirt-bright-background_488220-18413.avif" alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 5 weeks pregnant </span>
                </h4 >

                <p style={{textAlign:'justify'}}>Antenatal care (also called pregnancy or maternity care) is the care you get from doctors during your pregnancy to make sure you and your baby are as well as possible. Starting your maternity care early in pregnancy is important if you have a health condition that may affect your pregnancy, such as heart or lung conditions, epilepsy, diabetes or asthma. Your doctor will be able to advise you if you're taking medicines for your condition while you're pregnant. Do not stop taking any prescribed medicine without checking with your doctor or midwife first.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 6 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>It's normal to feel a range of emotions in pregnancy and everyone's experience is different. A healthy diet in pregnancy, having foods that are nutritious and safe to eat, is important for the wellbeing of you and your baby. There are lots of hormonal changes during this week. You may have morning sickness and tiredness, metallic taste in your mouth, heartburn and indigestion. All these are normal and can ask for supportive treatment from your doctor. Hormonal changes in your body may result in the acne outburst on your skin. It could result in excessive pimples on your face which is because of the increase in hormones.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 8 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Symptoms you’ve already been experiencing may continue during this week. Morning sickness, fatigue, heartburn, difficulty sleeping etc. You may experience a little bleeding in pregnancy. Always mention any bleeding in pregnancy to your doctor particularly if it continues and you get stomach pain.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 10 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>You'll be offered screening to find the baby's chance of having Down's syndrome, Edwards' syndrome and Patau's syndrome as part of your maternity care. Pregnant women and their babies are at higher risk from flu (influenza) and whooping cough .You're advised to have a flu vaccination in pregnancy and whooping cough vaccination in pregnancy to protect you and your baby. Sitting and standing on foot for a long time slows your circulating system. So, you should take a walk of 10 minutes once every two hours.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 11 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Headaches in early pregnancy can be caused by changes in your body. Find out what you can do about headaches in pregnancy and contact your doctor. Limiting the amount of caffeine you have in pregnancy can reduce the chances of miscarriage and your baby weighing less than normal when they're born (low birthweight).</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 12 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>You might notice you're a bit constipated. Not everyone gets constipation in pregnancy, but it's fairly common and can make you feel uncomfortable. You may feel stomach pain or cramps from time to time. These are usually nothing to worry about, and can be caused by constipation, or your ligaments growing as your womb gets bigger. But if you have stomach pain that does not go away, is severe or you also have bleeding, you need to see your doctor.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 13 weeks pregnant</span>
                </h4 >
                <p style={{textAlign:'justify'}}>If you've been feeling sick and tired with morning sickness, you'll probably start to feel better when you're around 13 or 14 weeks pregnant. You'll notice a small bump developing as your womb grows and moves upwards. Urinary tract infections (UTIs) can happen in pregnancy, and it's important to treat them quickly to reduce the risk of kidney infection. Pigmentation on the face and neck may happen. It is called a mask of pregnancy or chloasma. This is temporary.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 14 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>How much weight you put on in pregnancy depends on your weight before you get pregnant. You're likely to gain between 10 kg to 12.5kg. But gaining too much or too little may cause health problems for you or your baby. Since the 14th week, the effect of progesterone hormone and lactation happens in all smooth muscles and a drop in the blood pressure may be seen.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 16 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Itching in pregnancy can be a sign of the rare liver disorder intrahepatic cholestasis of pregnancy, sometimes called obstetric cholestasis or OC. Contact your doctor. You might also notice stretch marks. These are not harmful and don’t cause any medical problems.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 17 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your uterus is about to reach your umbilicus and you can feel it by your hand. You may have your allergies flare up. You will have dizziness, vision changes, stretch marks, and constipation increase in this week.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 18 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Stomach (abdominal) pain in pregnancy is common, and can be caused by constipation or "growing pains" as your ligaments stretch to support your growing uterus. You may also get headaches in pregnancy. This is common, but could be serious if the headaches are severe, contact the doctor</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 19 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>You might feel your baby move for the first time when you're between 18 and 20 weeks pregnant. At first, you feel a fluttering or bubbling, or a very slight shifting movement. Later on, you can even see the baby kicking. There's no set number of baby movements you should feel. Once you start to feel your baby's movements, you should feel your baby moving right up until they're born. </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 22 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Stretch marks become noticeable when you're around 22 to 24 weeks pregnant. They may appear on your stomach, breasts and thighs. At first, they can be pink, red, purple or brown, depending on your skin colour. Your breasts may start to leak a little pre-milk – this is normal. You may also notice skin changes in pregnancy.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 24 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Tiredness and lack of sleep are common in pregnancy. There are plenty of things you can do to help yourself sleep, including using pillows to support your growing bump. You may get thrush in pregnancy. Overheating when you're pregnant can be irritating but it will not harm your baby. See your doctor if you have any pain when you urinate. This could be a sign of a urinary tract infection (UTI) that needs treating.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 25 weeks pregnant </span>
                </h4>
                <p style={{textAlign:'justify'}}>Be sure to mention any swelling to your doctor so they can take your blood pressure and rule out a condition called pre-eclampsia, which can cause swelling. Other symptoms of preeclampsia include severe headache, vision problems such as blurring or flashing lights, and pain under the ribs. It's common to get backache in pregnancy as your uterus grows. Restless Leg Syndrome, makes you feel the urge to move your legs constantly to relieve a tingling or crawling sensation due to nerve hypersensitivity.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 26 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Pregnancy and birth can weaken the muscles of the pelvic floor and you may notice urine leak when you cough, sneeze or strain your stomach muscles. Your pelvic floor is made up of layers of muscles inside your body that stretch like a hammock from the pubic bone (in front) to the end of the spine. Pelvic floor exercises can help strengthen the muscles so they work better.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 27 weeks pregnant </span>
                </h4>
                <p style={{textAlign:'justify'}}>Nosebleeds are quite common in pregnancy because of hormonal changes. Often there's nothing to worry about and they usually stop with a treatment</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 28 weeks pregnant</span>
                </h4>
                <p style={{textAlign:'justify'}}>As your stomach gets more and more pressure you could find yourself feeling sensitive to different food, especially oily and fatty food and spicy foods. If you've been told your blood type is rhesus negative, you'll be offered an injection in pregnancy to protect your unborn baby.  This heartburn or acid reflux is common in pregnancy. It can be uncomfortable but is not harmful. Find out how to avoid indigestion and heartburn in pregnancy and how to ease the symptoms.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 29 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>As your bump pushes up against your lungs and you have extra weight to carry around, you may feel breathless. Faster growing nails are a symptom related to changes in hormones.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 30 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>You have all of your muscles, bones, hormones and blood vessels working for two people. All of this can cause cramps. Leg cramps at night are common at around 29 to 32 weeks. You may find it hard to sleep because you cannot get comfortable. Try lying curled up on your side with a pillow between your legs and a cushion under your bump.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 31 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Check your blood pressure at every appointment and regularly. This is because high blood pressure can be harmful for you and your baby, and can be an early sign of preeclampsia. Other signs of preeclampsia include a bad headache, vision problems and pain below your ribs.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 32 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Being active and fit during pregnancy will help you adapt to your changing shape and weight gain. It can also help you cope with labor and get back into shape after the birth. You may develop pelvic pain in pregnancy. This is not harmful to your baby, but it can cause severe pain and make it difficult for you to get around.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 33 weeks pregnant  </span>
                </h4 >
                <p style={{textAlign:'justify'}}>It's never too early to start thinking about how you're going to feed your baby.

                </p>
                <p style={{textAlign:'justify'}}>benefits of breastfeeding for you and your baby.</p>
                <ul>
                    <li>your breast milk meets all of your baby’s nutritional needs</li>
                    <li>breast milk protects your baby from infections and diseases</li>
                    <li>breastfeeding provides health benefits for you</li>
                    <li>breast milk is available for your baby whenever your baby needs it</li>
                    <li>breastfeeding can build a strong emotional bond between you and your baby.</li>

                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 34 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your placenta grows in your womb during pregnancy. It brings oxygen and nutrients to your growing baby and takes waste products out of your baby's blood. The placenta attaches to the wall of the uterus, and the baby's umbilical cord arises from it. The organ is usually attached to the top, side, front or back of the uterus. In rare cases, the placenta might attach in the lower area of the uterus. When this happens, it's called a low-lying placenta (placenta previa).</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 35 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Labor that starts before 37 weeks is considered premature. If your baby is born early, they may need special care in the hospital. You should still be feeling your baby move, right up until they're born. If you notice any change in your baby's movements, call your doctor straight away so your baby can be checked.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 36 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>You may be aware of a tightening feeling in your lower tummy from time to time. These are a normal part of pregnancy, known as Braxton Hicks contractions – your uterus is "practicing" for the tightening, or contractions, of labor. When contractions become longer, stronger and more frequent, it can be a sign that labor is starting.</p>
                <p style={{textAlign:'justify'}}>Information:</p>
                <p style={{textAlign:'justify'}}>Call your hospital when your contractions are in a regular pattern, coming every 5 minutes and lasting at least 60 seconds.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 37 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>When you're around 37 weeks pregnant, if it's your first pregnancy, your baby moves down ready to be born. You may feel more comfortable when this happens, and you'll probably also feel increased pressure in your lower abdomen. If it's not your first pregnancy, the baby may not move down until labor.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 38 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Labour usually starts between 38 and 42 weeks of pregnancy.</p>
                <p style={{textAlign:'justify'}}>signs that labor has begun and what happens. There are several signs that labor might be starting, including contractions or tightenings</p>
                <ul>
                    <li>a "show", when the plug of mucus from your cervix (entrance to your womb, or uterus) comes away</li>
                    <li>backache</li>
                    <li>an urge to go to the toilet, which is caused by your baby's head pressing on your bowel</li>
                    <li>your waters breaking</li>
                </ul>
                <p style={{textAlign:'justify'}}>The early (latent) stage of labor can take some time.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 39 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Learn about pain relief in labor.<b> Your pain relief options</b></p>
                <ul>
                    <li>Relax, stay calm and breathe deeply</li>
                    <li>keep moving – your position can make a difference, so try kneeling, walking around, or rocking backwards and forwards</li>
                    <li>ave a bath</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Epidural (Anesthesia) </span>
                </h4 >
                <p style={{textAlign:'justify'}}>An epidural is a type of local anesthetic. It numbs the nerves that carry the pain impulses from the birth canal to the brain. It should not make you sick or drowsy. In most cases, an epidural gives complete pain relief. It can be helpful if you are having a long or particularly painful labor. An anesthetist is the only person who can give an epidural, so it will not be available at home. If you think you might want one, check with the doctor. </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>You are 40 weeks pregnant </span>
                </h4 >
                <p style={{textAlign:'justify'}}>Pregnancy normally lasts about 40 weeks – from the first day of your last period. Labour usually starts a week either side of this date. If it doesn’t start, you'll be offered a membrane sweep. A membrane sweep involves having a vaginal examination that stimulates the cervix to produce hormones that may trigger natural labor.</p>
            </div >
        </>
    )
}

export default Mother_News