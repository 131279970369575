import React from 'react'

const Folic_Acid_Supplementation_and_Pregnancy = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>Folic Acid Supplementation and Pregnancy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">

                        <p style={{ textAlign: 'justify' }}>
                            Every woman of reproductive age needs to get folic acid every day, whether she is planning to get pregnant or not, to help make new cells.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>About folic acid</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Folic acid is a B vitamin. Our bodies use it to make new cells. Think about the skin, hair, and nails. These–and other parts of the body – make new cells each day. Folic acid is the synthetic (that is, not generally occurring naturally) form of folate used in supplements and in fortified foods such as rice, pasta, bread, and some breakfast cereals.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Why folic acid is important before and during pregnancy</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    When the baby is developing early during pregnancy, folic acid helps form the neural tube. Folic acid is very important because it can help prevent some major birth defects of the baby’s brain (anencephaly) and spine (spina bifida). The neural tube forms the early brain and spine.
                </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Folic-Acid-Tablets.jpg' alt=''></img>
                    </div>
                </div>
              
                <p style={{ textAlign: 'justify' }}>
                    Women of reproductive age need 400 mcg of folic acid every day
                </p>
                <p>All women of reproductive age should get 400 mcg of folic acid every day to get enough folic acid to help prevent some birth defects because</p>
                <ul>
                    <li>About half of pregnancies are unplanned, and</li>
                    <li>Major birth defects of the baby’s brain or spine occur very early in pregnancy (3-4 weeks after conception), before most women know they are pregnant.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How to get enough folic acid to prevent neural tube defects</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    In addition to eating foods with folate from a varied diet, women can get folic acid from
                </p>

                <ul>
                    <li>Taking a vitamin that has folic acid in it:</li>
                    <ul>
                        <li>Most vitamins sold in the United States have the recommended daily amount of folic acid (400 mcg) that women need for the prevention of neural tube defects. </li>
                    </ul>
                    <li>Eating fortified foods:</li>
                    <ul>
                        <li>You can find folic acid in some breads, breakfast cereals, and corn masa flour.</li>
                    </ul>
                    <li>Getting a combination of the two: taking a vitamin that has folic acid in it and eating fortified foods.</li>
                </ul>
            </div>
        </>
    )
}

export default Folic_Acid_Supplementation_and_Pregnancy