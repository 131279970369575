import React from 'react'

const Diabetes = () => {
  return (
    <>

      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Diabetes Treatment In Guntur</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Diabetes</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Diabetes mellitus is a metabolic disease that leads to high blood sugar. The hormone insulin moves sugar from the blood into your cells to be stored or used for energy. The hormone insulin is responsible to allow glucose in the blood to enter cells to provide the energy to function. With diabetes, your body is not able to make sufficient insulin or use the insulin it has made. Diabetes is treated by Dr.Kalyan Chakravarthy Koganti M.D.(Gen.) in a methodical manner giving importance to diet and exercise and not just drugs alone through a specialist program called ATM. And also Diabetes specialist in Guntur with a few different medications at Help Hospital <a href='http://helphospital.in/'><span>best diabeties hospital in Guntur</span></a>. Some of these drugs are taken by mouth, while others are available as injections. If high blood sugar is not treated by diabetologist in Guntur, it may damage your eyes, nerves, kidneys, and other organs. Visit <a href='http://helphospital.in/Dr_Kalyan_Chakravarthy_Koganti'><span>best diabetes doctor in Guntur</span></a> at best clinic if you have signs and symptoms of diabetes.
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Types Of Diabetes:</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              <b>Type 1 Diabetes:</b>  It is an autoimmune disease where the immune system attacks and damages cells in the pancreas (where insulin is made).
            </p>
            <p style={{ textAlign: 'justify' }}>
              <b>Type 2 Diabetes:</b> It exists as your body acts as resistant to insulin and sugar is built up in your blood.
            </p>
          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Diabetes.jpg' alt=''></img>
          </div>
        </div>
       
        {/* <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Symptoms Of Fever Include:</span>
        </h4 > */}
        
        <p style={{ textAlign: 'justify' }}>
          <b>Prediabetes: </b> 
          It is present when you have high blood sugar, but it’s not high enough for a diagnosis of type 2 diabetes.
        </p>
        <p style={{ textAlign: 'justify' }}>
          <b>Gestational Diabetes: </b> 
          It is a high blood sugar during pregnancy. The main cause of this type of diabetes is Insulin-blocking hormones produced by the placenta.
        </p>
        <p style={{ textAlign: 'justify' }}>
        In case of high fevers, an individual may have extreme irritability, delirium, confusion, and seizures.
        </p>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Diabetes Treatment:</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        Diabetes can be treated at diabetic clinic in Guntur by controlling blood sugar levels to prevent complications of the disease.
        </p>
        <p>
        In Type 1 diabetes, insulin has to be managed with dietary changes and exercise. In Type 2 diabetes, insulin may be managed with non-insulin medications, dietary changes and weight reduction.
        </p>
        <p>
        Get the best treatment for High Blood Pressure (High BP) in guntur from Help Hospital
        </p>
      </div>
    </>
  )
}

export default Diabetes