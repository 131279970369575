import React, { useCallback, useState } from 'react'

const How_Much_Weight_should_Be_Gain_During_Pregnancy = () => {
    // state
  const [weight, setWeight] = useState(0)
  const [height, setHeight] = useState(0)
  const [bmi, setBmi] = useState('')
  const [message, setMessage] = useState('')
 
  let calcBmi = (event) => {
    //prevent submitting to the server
    event.preventDefault()
 
    if (weight === 0 || height === 0) {
      alert('Please enter a valid weight and height')
    } else {
        let height1=height*0.304
      let bmi = (weight / (height1 * height1) )
      setBmi(bmi.toFixed(1))
        console.log(bmi)
      // Logic for message
 
      if (bmi < 18) {
        setMessage('You are underweight')
      } else if(bmi>=18&&bmi<25){
        setMessage('You are normal weight')
      }else if (bmi >= 25 && bmi < 30) {
        setMessage('You are over weight')
      } else {
        setMessage('You are Obesity')
      }
    }
  }
 
 
  let reload = () => {
    window.location.reload()
  }
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>How much weight should you gain during pregnancy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">

                        <p style={{ textAlign: 'justify' }}>
                            Calculate your BMI and weight category using your weight before you became pregnant
                        </p>
                        <div className='container'>
                <center>
                <div className='card bmi-card'>
                <h2 className='center'>BMI Calculator</h2>
                <form onSubmit={calcBmi} className='bmi-form'>

                    <div className='bmi-div'>
                        <label>Weight (Kgs)</label>
                        <input value={weight} onChange={(e) => setWeight(e.target.value)} />
                    </div>

                    <div className='bmi-div'>
                        <label>Height (Ft)</label>
                        <input value={height} onChange={(event) => setHeight(event.target.value)} />
                    </div>

                    <div className='bmi-div'>
                        <button className='btn btn-Submit' type='submit'>Submit</button>
                        <button className='btn btn-outline' onClick={reload} type='submit'>Reload</button>
                    </div>
                </form>

                <div className='center'>
                    <h3>Your BMI is: {bmi}</h3>
                    <p style={{color:'red'}}>{message}</p>
                </div>
                </div>
                </center>
            </div>

                       

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-2">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey', marginTop: '25px' }} id="ServicesImG" src='weight-gain.png' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}>
                            Weight gain recommendations for women pregnant with one baby
                        </p>
                        <table>
                            <tr>
                                <th className='p-3'>If before pregnancy,you were…</th>
                                <th className='p-3'>You should gain…</th>
                            </tr>
                            <tr>
                                <td className='p-3'><b>Underweight :</b> bmi less than 18.5</td>
                                <td className='p-3'>12 to 18 kgs</td>
                            </tr>
                            <tr>
                                <td className='p-3'><b>Normal weight :</b> bmi 18.5-24.9</td>
                                <td className='p-3'>11 to 15 kgs</td>
                            </tr>
                            <tr>
                                <td className='p-3'>
                                    <b>Overweight :</b> bmi 25.0-29.9
                                </td>
                                <td className='p-3'>6 to 11 kgs</td>
                            </tr>
                            <tr>
                                <td className='p-3'><b>Obese :</b>
                                    bmi greater than or equal to 30.0
                                </td>
                                <td className='p-3'>4 to 9 kgs</td>
                            </tr>
                        </table>
                <p className='mt-2' style={{ textAlign: 'justify' }}>
                    Weight gain recommendations for women pregnant with twins
                </p>
                <table className='mb-3'>
                    <tr>
                        <th className='p-3'>If before pregnancy,you were…</th>
                        <th className='p-3'>You should gain…</th>
                    </tr>
                    <tr>
                        <td className='p-3'><b>Underweight :</b> bmi less than 18.5</td>
                        <td className='p-3'>22 to 28 kgs</td>
                    </tr>
                    <tr>
                        <td className='p-3'><b>Normal weight :</b> bmi 18.5-24.9</td>
                        <td className='p-3'>16 to 24 kgs</td>
                    </tr>
                    <tr>
                        <td className='p-3'>
                            <b>Overweight :</b> bmi 25.0-29.9
                        </td>
                        <td className='p-3'>14 to 22 kgs</td>
                    </tr>
                    <tr>
                        <td className='p-3'><b>Obese :</b>
                            bmi greater than or equal to 30.0
                        </td>
                        <td className='p-3'>11 to 19 kgs</td>
                    </tr>
                </table>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Why is it important to gain the recommended amount of weight during pregnancy?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Gaining less than the recommended amount of weight in pregnancy is associated with delivering a baby who is too small. Some babies born too small may have difficulty starting breastfeeding, may be at increased risk for illness, and may experience developmental delays (not meeting the milestones for his or her age).
                </p>
                <p>Gaining more than the recommended amount of weight in pregnancy is associated with having a baby who is born too large, which can lead to delivery complications, cesarean delivery, and obesity during childhood. Gaining more than the recommended amount of weight can also increase the amount of weight you hold on to after pregnancy, which can lead to obesity.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What steps can you take to meet pregnancy weight gain recommendations?</span>
                </h4>
                <ul>
                    <li>Know your caloric needs.</li>
                    <li>Work with your health care provider on your weight gain goals at the beginning of and regularly throughout your pregnancy.</li>
                    <li>Track your pregnancy weight gain at the beginning and regularly throughout pregnancy</li>
                    <li>Eat a balanced diet</li>
                    <li>Limit added sugars and solid fats</li>
                    <li>Work up to or maintain at least 150 minutes (2 ⅟2 hours) of moderate intensity aerobic activity (such as brisk walking) per week. </li>
                </ul>
            </div>

           
        </>
    )
}

export default How_Much_Weight_should_Be_Gain_During_Pregnancy