import React from 'react'

const General_Medicine = () => {
  return (
    <>
      <div style={{ paddingTop: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>General Medicine</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>

          </ul>
        </div>
      </div>


      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>General Medicine</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Our primary care general medicine doctors in Guntur are often the first point of contact with patients because they are considered as the first line of care in the community. Best general medicine specialist in Guntur render personalized care for patients of different age groups. Some of the other general problems include:
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Acute Respiratory Infection</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              Infections that occur in the upper respiratory tract (ears, nose, throat, and sinuses) or lower respiratory tract (trachea, bronchial tubes, and lungs) are usually caused by a virus
            </p>
            <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Asthma</span>
            </h4 >
            <p style={{ textAlign: 'justify' }}>
              Asthma is a chronic disorder which affects the airways. The airways are swollen and sensitive to allergens or irritants in people who have asthma. Get the treatment for Asthma in Guntur at Best hospital in Guntur and live a healthy life.
            </p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body" style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='general-medicine.jpg' alt=''></img>
          </div>
        </div>
        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Bronchitis</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
          Bronchitis is the inflammation of the airways which may be acute (short-term) or chronic (long-term).It is caused due to smoking. Treatment for bronchitis in Guntur is based on the type and duration of bronchitis.
        </p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Fatigue</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        Fatigue is the lack of energy including many possible causes like sleep disorders, anemia, an underactive thyroid gland and depression. A medical history, physical examination and blood tests may be recommended by general medicine doctor at Guntur in determining the causes of persistent fatigue.
        </p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Influenza</span>
        </h4 >
        <p style={{ textAlign: 'justify' }}>
        Influenza is a contagious respiratory illness. It is caused by a virus. Symptoms are headache, fever, dry cough, runny or stuffy nose, sore throat muscle aches and stomach symptoms.
        </p>


        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Influenza</span>      
        </h4>
        <p style={{ textAlign: 'justify' }}>
        Menopause is when a woman stops to menstruate. Symptoms involve night sweats, hot flashes, insomnia, vaginal dryness, mood swings, decreased sex drive, and urinary tract infections. Hormone replacement therapy can alleviate symptoms but it has both risks and benefits which have to be discussed thoroughly with a doctor before treatment.
        </p>

        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Osteoarthritis</span>      
        </h4>
        <p style={{ textAlign: 'justify' }}>
        It is a joint disease affecting the cartilage, the shock-absorbing tissue which covers the ends of bones in a joint. Methods to minimize discomfort and supporting joint health involve rest, weight loss, exercise and medication for pain and swelling.
        </p>

        
        <p style={{ textAlign: 'justify' }}>
        Apart from all these conditions, eye infection treatment, nose infection treatment, throat infection treatment and treatment for sinuses are also provided in Guntur.
        </p>
      </div>
    </>
  )
}

export default General_Medicine