import React from 'react'

const VACCINES_DURING_PREGNANCY = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>VACCINES DURING PREGNANCY</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Are vaccines safe during pregnancy?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Certain vaccines are safe and recommended for women before, during, and after pregnancy to help keep them and their babies healthy. The antibodies mothers develop in response to these vaccines not only protect them, but also cross the placenta and help protect their babies from serious diseases early in life. Vaccinating during pregnancy also helps protect a mother from getting a serious disease and then giving it to her newborn.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Which vaccines should i get if i am pregnant?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            If you are pregnant or planning a pregnancy, the specific vaccines you need are determined by your age, lifestyle, medical conditions, travel, and previous vaccinations.
                        </p>
                        <p style={{ textAlign: 'justify' }}>CDC recommends that pregnant women get two vaccines during every pregnancy: </p>
                        <ul>
                            <li>Inactivated flu vaccine (the injection, not the live nasal flu vaccine) and</li>
                            <li>Tdap vaccine.</li>
                        </ul>



                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '80%', boxShadow: 'grey' }} id="ServicesImG" src='Vaccine-image.png' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Flu vaccine</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    CDC recommends getting the flu vaccine if you are pregnant during flu season. While flu seasons vary in their timing, CDC recommends getting vaccinated by the end of October, if possible. Getting vaccinated later during flu season, though, can still be beneficial. Flu vaccines have been given to millions of pregnant women over the years, and scientific evidence shows that it is safe. Getting the flu vaccine during pregnancy is one of the best ways to protect yourself and your baby for several months after birth from flu-related complications.
                </p>

                <p style={{ textAlign: 'justify' }}>
                    Tdap vaccine
                </p>

                <p style={{ textAlign: 'justify' }}>
                    Pregnant women are also encouraged to get the Tdap vaccine at any time during pregnancy, but optimally between 27 and 36 weeks of each pregnancy, to protect yourself and your baby from pertussis, also known as whooping cough. This vaccine is recommended during every pregnancy, regardless of how long it has been since you previously received the Tdap vaccine.
                </p>
            </div>
        </>
    )
}

export default VACCINES_DURING_PREGNANCY