import React from 'react'

const How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>How to Get Pregnant – Best Tips to Help You Conceive</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>



      <div className="container mt-2 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>How to Get Pregnant – Best Tips to Help You Conceive</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education. 
                Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID specialist of our state. */}
              Embarking on the path to parenthood is an exciting and transformative journey. For some, conception can happen swiftly, while others may face unique challenges. In this blog post, we'll explore a range of effective tips and strategies that can enhance your chances of getting pregnant. From lifestyle adjustments to essential medical insights, these recommendations are designed to support you on this incredible adventure.          </p>

            <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Understand Your Menstrual Cycle</span>
            </h5>

            <p style={{ textAlign: 'justify' }}>Getting familiar with your menstrual cycle is paramount. Track the length and regularity of your periods, and use ovulation prediction kits or apps to pinpoint your most fertile days. This knowledge will help you time intercourse for optimal chances of conception.</p>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg mt-5">
            <img className=" img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '300px', boxShadow: 'grey' }} id="ServicesImG" src='tips for pregnant.webp' alt=''></img>
          </div>
        </div>


        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}> Maintain a Healthy Lifestyle</span>
        </h5 >
        <p style={{ textAlign: 'justify' }}>A balanced diet, regular exercise, and avoiding harmful habits like smoking and excessive alcohol consumption can significantly improve fertility. Aim for a diet rich in fruits, vegetables, whole grains, and lean proteins.</p>
        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Manage Stress</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>High stress levels can disrupt hormonal balance, potentially affecting ovulation and fertility. Incorporate stress-reducing practices like meditation, yoga, or deep breathing exercises into your daily routine.</p>
        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Achieve a Healthy Body Weight</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>Both underweight and overweight conditions can impact fertility. Achieving a healthy body mass index (BMI) can enhance your chances of conception. Consult a healthcare provider for personalized guidance.</p>

        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}> Know Your Partner's Health</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>Fertility isn't solely a female concern. It's crucial for both partners to maintain their overall health. Encourage your partner to adopt a healthy lifestyle and consider a preconception check-up for them as well.</p>

        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Optimize Intimacy Timing</span>
        </h5>

        <p style={{ textAlign: 'justify' }}>Regular, timed intercourse during your fertile window (usually around ovulation) can significantly increase your chances of conceiving. Aim for every 2-3 days during this period.</p>
        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Seek Medical Advice if Needed</span>
        </h5 >
        <p style={{ textAlign: 'justify' }}>If you've been actively trying to conceive for a year without success (or six months if you're over 35), consider consulting a healthcare provider. They can conduct tests to identify any underlying issues and provide tailored guidance.</p>
        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Take Prenatal Vitamins</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>Start taking prenatal vitamins that contain folic acid before conception. Folic acid is crucial for early fetal development and can help prevent certain birth defects.</p>

        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Limit Caffeine Intake</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>Excessive caffeine consumption has been associated with a higher risk of infertility. Consider reducing your caffeine intake, especially if you consume multiple cups of coffee a day.</p>

        <h5 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>Be Patient and Stay Positive</span>
        </h5>
        <p style={{ textAlign: 'justify' }}>Remember that conception can take time. It's normal for it not to happen immediately. Stay positive, communicate openly with your partner, and seek support from friends, family, or online communities.</p>


      </div>

    </>
  )
}

export default How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive