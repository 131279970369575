import React from 'react'

const Exercise_During_Pregnancy = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>Exercise During Pregnancy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>1.	Start exercising: </span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            It’s never too late to get healthy once you’ve decided you want a baby. Exercising is a great way to reach a healthy weight before a baby! Moderate exercise like walking and swimming for 30 minutes stimulates the bowels.                         </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>2.	Stay active :</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Walk for 30 minutes, several times a week. You can do dancing or swimming as well. Women that are physically more active during the pregnancy have a shorter labor time. Walking and swimming can reduce the swelling and provide you with a temporary relief. Don’t forget to drink plenty of water and stay hydrated.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>3.	Exercise regularly: </span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            It’s perfectly safe to exercise during your pregnancy. If you’re not feeling too tired or nauseous, make some time to build up your endurance and strengthen your muscles. The safest and most productive activities are  swimming,
                        </p>


                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '100%', boxShadow: 'grey' }} id="ServicesImG" src='ExerciseImage.png' alt=''></img>
                    </div>
                </div>

                <ul style={{marginTop:'-20px'}}>
                    <li> brisk walking, </li>
                    <li>indoor stationary cycling, </li>
                    <li>step or elliptical machines</li>
                    <li>low-impact aerobics (taught by a certified aerobics instructor). </li>
                </ul>

                <p style={{ textAlign: 'justify' }}>
                    These activities carry little risk of injury, benefit your entire body, and can be continued until birth. Other activities such as jogging can be done in moderation, especially if you were doing them before your pregnancy. You may want to choose exercises or activities that do not require great balance or coordination, especially later in pregnancy
                </p>

                <p style={{ textAlign: 'justify' }}>
                    4.	Maintaining a regular exercise routine throughout your pregnancy can help you stay healthy and feel your best. Regular exercise during pregnancy can improve your posture and decrease some common discomforts such as backaches and fatigue. There is evidence that physical activity may prevent gestational diabetes (diabetes that develops during pregnancy), relieve stress, and build more stamina needed for labor and delivery.
                </p>
            </div>
        </>
    )
}

export default Exercise_During_Pregnancy