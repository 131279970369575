import React from 'react'

const When_to_do_pregnancy_kit_test_at_home = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>When To Do Pregnancy Kit Test At Home</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>When To Do Pregnancy Kit Test At Home</span>
                        </h1>

                        <p style={{ textAlign: 'justify' }}>
                            You can carry out most pregnancy tests from the first day of a missed period. If you don't know when your next period is due, do the test at least 21 days after you last had unprotected sex. Some very sensitive pregnancy tests can be used even before you miss a period.

                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>How does a pregnancy test work?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>All pregnancy tests detect the hormone human chorionic gonadotrophin (hCG), which starts to be produced around 6 days after fertilisation.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Will a pregnancy test work if I'm on the pill?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>Yes. Some methods of contraception, such as the contraceptive pill, contain hormones, but these hormones will not stop a pregnancy test working.</p>
                        <p style={{ textAlign: 'justify' }}>No method of contraception is completely effective, so it's important to take a pregnancy test if you think you might be pregnant.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='tips for pregnant.webp' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Accuracy of Pregnancy test results</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Home pregnancy tests are accurate as long as you follow the instructions correctly.
                </p>
                <p style={{ textAlign: 'justify' }}>A positive test result is almost certainly correct. However, a negative test result is less reliable.</p>
                <p style={{ textAlign: 'justify' }}>The result may not be reliable if you:</p>
                <ul>
                    <li>do not follow the instructions properly</li>
                    <li>take the test too early</li>
                </ul>
                <p style={{ textAlign: 'justify' }}>Some medicines can also affect the results.</p>

                <p style={{ textAlign: 'justify' }}>
                    If you get a negative result and still think you're pregnant, wait a few days and try again. Speak to your GP if you get a negative result after a second test but your period has not arrived.
                </p>

            </div >
        </>
    )
}

export default When_to_do_pregnancy_kit_test_at_home