import React from 'react'

const When_does_a_pregnancy_start = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>When does a pregnancy start?</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>When Does A Pregnancy Start ?</span>
                        </h1>

                        <p style={{ textAlign: 'justify' }}>
                        The start of pregnancy is actually the first day of your last menstrual period (LMP). This is the gestational age of the fetus. It’s about two weeks ahead of when conception actually occurs. Though it may seem strange, the date of the first day of your last period will be an important date when determining your due date. Your healthcare provider will ask you about this date and will use it to figure out how far along you are in your pregnancy.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Calculating pregnancy duration</span>
                        </h4 >
                       <p>An unborn baby spends around 38 weeks in the womb, but the average length of pregnancy (gestation) is counted as 40 weeks.</p>
                       <p>This is because pregnancy is counted from the first day of the woman’s last period, not the date of conception, which generally occurs 2 weeks later.</p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='When-Do-You-Start-To-Look-Pregnant.png' alt=''></img>
                    </div>
                </div>

            </div >
        </>
    )
}

export default When_does_a_pregnancy_start