import React from 'react'

const Infertility_When_to_Seek_Medical_Help = () => {
   return (
        <>
            <div style={{ paddingTop: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Infertility: When to Seek Medical Help?</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Infertility: When to Seek Medical Help?</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Your doctor says you are INFERTILE. Don't you feel a plethora of emotions that make you feel stressed out? Will I be able to conceive ever? How will my family take this pain? Will there be a proper diagnosis available? There is an irksome pressure that holds the women emotions and decisions when they learn about their fertility.</p>
                        <p style={{ textAlign: 'justify' }}>
                            Without any doubt, Infertility is rising! Over six couples today struggle with infertility (the biological inability to conceive or carry a pregnancy to full term). The doctor says factors mentioned below contribute to this staggering and continuously growing statistic.
                        </p>
                        <ul>
                            <li>Delayed Childbearing</li>
                            <li>Advanced Maternal Age</li>
                            <li>Medical Conditions</li>
                            <li>Sexually transmitted diseases</li>
                            <li>Obesity and Environmental Factors</li>
                        </ul>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body" style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='WhenToSeekMedical.webp' alt=''></img>
                    </div>
                </div>
                <p>Most importantly, as women continue to work and delay in having children, their needs with regard to infertility treatment have risen considerably.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>When to seek medical help?</span>
                </h4 >
                <ul>
                    <li>See your doctor if you haven’t conceived after one year of trying</li>
                    <li>You are 36+ because the decline in fertility speeds up when a woman reaches her mid-30s.</li>
                    <li>Seeking treatment for Polycystic Ovaries, Endometriosis or Cancer</li>
                </ul>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What can you expect in the treatment?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    The fertility tests can take some time so it is best to make an appointment early. The specialist will be able to carry out an initial assessment to check what may be causing fertility problems and advice you for the next step.
                </p>
                <p>The doctor says, when it comes to fertility tests, males are a little shy and apprehensive about the whole process. But as an expert, it is always best for both the partners to visit as infertility can be a problem in a man or woman, or sometimes both. Trying to conceive is an emotional process so it is important to support each other as much as possible.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Are there any infertility treatments available?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Fertility treatments for women are:
                </p>

                <ul>
                    <li>Medication Treatments for Female Infertility: Usually drugs are given to patients to stimulate ovulation. The surgical treatments mostly involve hysteroscopy and laparoscopy. These procedures allow us to determine whether there are any defects like blocked tubes, endometriosis, fibroid tumours, uterine polyps and other abnormalities of the uterus, fallopian tubes and ovaries. If there are any defects that are found they can be corrected with operative laparoscopy as it offers a faster recovery, minimal blood loss, less scarring and reduces the stay in the hospital.</li>
                    <li>Assisted Reproductive Technology (ART): If the woman does not become pregnant even after undergoing medical and surgical procedures then she needs to undergo more complex procedures, called ART like:</li>
                    <ul>
                        <li>Intrauterine Insemination (IUI)</li>
                        <li>In Vitro Fertilization/Intra Cytoplasmic Sperm Injection</li>
                        <li>Third Party Assisted ART</li>
                        <li>Sperm Donation</li>
                        <li>Egg Donation</li>
                        <li>Surrogacy</li>
                    </ul>
                </ul>
              
            </div>
        </>
    )
}

export default Infertility_When_to_Seek_Medical_Help