import React ,{ useEffect, useState } from 'react'


const Testmonialss = () => {

    const peoples = [
        {
          id: 1,
          image: "Avatar1.png",
          name: "Rajesh daadi ",
          quote:
          "Dr. Bharathi mam trys her best for normal delivery. After hearing so many good reviews from relatives and friends, We have joined my sister for her first delivery and we blessed with a baby boy - normal delivery. Within the hospital we have all required facilities like laboratory and pharmacy so you no need need to go outside for tests and medicines. Rooms are hygeine and you don't feel like ur in a hospital. I highly recommend this hospital. They also have highly equipped fertility center..",
        },
        {
          id: 2,
          image: "Avatar1.png",
          name: "Ratna kumari ",
          quote:"Dr. kalyan chakravarthy koganti sir treated my father in law very well.  We visited many places for urine infection. Finally we got a correct place here. Here hospital staff also very cooperative.  Nurses are very good and attentive.",
        },
        {
          id: 3,
          image: "Avatar1.png",
          name: "Jany Basha Shaik",
          quote:
            "Myself is the patient admitted in Shri hospital under Dr.Kalyan chakravarthy koganti sir with vomitings and fever. I was scared with those symptoms. But finally I'm happy with the treatment given by the Dr. Kalyan sir and the hospital staff. Dr. Rama Krishna sir in ICU treated me accordingly the symptoms. I felt very happy. Finally I recovered",
        },
        {
          id: 4,
          image: "Avatar1.png",
          name: "Sravani",
          quote:
            "I highly recommend help hospital guntur for pregnant ladies. We had great experience here and I really appreciate everyone efforts to make every thing smooth. Dr. Bharathi mam is very helpfull, and her treatment is very good. Staff is polite and Very Friendly. I honestly recommend this hospital for pregnant ladies. Thanks help hospital team.",
        },
        // {
        //   id:5 ,
        //   image: "Avatar1.png",
        //   name: "apple moulalia",
        //   quote:"I ADMIT SHRI HOSPITAL UNDER DR. KALYAN CHAKRAVARHTY KANDULA GASTROENTEROLOGY. SIR TREATMENT WELL AND GOOD, NICE HOSPITAL, ONE OF THE BEST HOSPITAL, THANK YOU TO DR. KALYAN CHAKRAVARHTY SIR 🙏🙏"
        // },
      ];

      const [people] = useState(peoples);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);
  return (
    <>
    
    <section className="container col section text-center mt-3 ">
      
      <div className="section-center " style={{justifyContent:'center'}}>
        {people.map((item, indexPeople) => {
          const { id, image, name, quote } = item;
          let position = "nextSlide";
          if (indexPeople === index) {
            position = "activeSlide";
          }
          if (
            indexPeople === index - 1 ||
            (index === 0 && indexPeople === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article className={position } key={id} >
              <img src={image} alt={name} className="person-img" />
             
              <h4>{name}</h4>
              <p className="text">{quote}</p>
            </article>
          );
        
        })}
        </div>
        </section>
        </>
  )
}

export default Testmonialss