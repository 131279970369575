import React from 'react'

const Pcos_pcod = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>PCOS/PCOD TREATMENT IN GUNTUR</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>PCOS/PCOD</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Are you struggling with the challenges of Polycystic Ovary Syndrome (PCOS) or Polycystic Ovary Disease (PCOD)? Look no further! At Help Hospital, we specialize in providing comprehensive and personalized treatment options for PCOS/PCOD. Our experienced medical professionals and state-of-the-art facilities make us a leading destination for PCOS/PCOD treatment in Guntur. In this blog post, we will delve into the details of our specialized care and highlight the benefits of choosing our hospital for your PCOS/PCOD needs.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Expert Multidisciplinary Team:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            At Help Hospital, we have a dedicated team of gynecologists, endocrinologists, nutritionists, and other healthcare professionals who work collaboratively to diagnose, manage, and treat PCOS/PCOD effectively. Our specialists have extensive experience in dealing with the complexities of this condition and stay updated with the latest advancements in PCOS/PCOD treatment.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='PCOD-PCOD.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Advanced Diagnostic Facilities:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Accurate diagnosis is the key to effective treatment. Our hospital is equipped with advanced diagnostic technologies that aid in the precise evaluation of PCOS/PCOD. From hormonal assays to ultrasound examinations, we utilize cutting-edge tools to assess the condition of your ovaries, hormone levels, and other relevant factors, enabling us to tailor treatment plans specifically to your needs.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Individualized Treatment Plans:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    We understand that each patient's PCOS/PCOD journey is unique, and one size does not fit all. That's why we take a personalized approach to treatment. After a thorough evaluation, our medical team will design a customized treatment plan that addresses your specific symptoms, concerns, and long-term goals. Our treatment options may include lifestyle modifications, medications, hormonal therapy, fertility management, and surgical interventions if necessary.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Holistic Care and Counseling:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                Managing PCOS/PCOD involves more than just medical intervention. We believe in providing holistic care to our patients. Our hospital offers dedicated counseling sessions with experienced psychologists to help you cope with the emotional and psychological aspects of the condition. Additionally, our nutritionists can guide you in adopting a healthy diet and exercise routine to manage weight and improve overall well-being.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Ongoing Monitoring and Support:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                At Help Hospital, we are committed to long-term care and support for our patients. We schedule regular follow-up appointments to monitor your progress, adjust treatment plans as needed, and provide guidance on lifestyle modifications. Our aim is to empower you with the knowledge and tools necessary to manage PCOS/PCOD effectively and lead a fulfilling life.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Conclusion:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                If you or someone you know is seeking reliable and comprehensive PCOS/PCOD treatment in Guntur, Help Hospital is your go-to destination. Our team of experts, advanced facilities, individualized treatment plans, holistic care approach, and ongoing support ensure that you receive the best possible care for your PCOS/PCOD needs. Don't let PCOS/PCOD hinder your quality of life - reach out to us today and take the first step towards a healthier and happier future.
                </p>
            </div>
        </>
    )
}

export default Pcos_pcod