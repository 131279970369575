import React from 'react'

const Birth_help = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>BIRTH HELP</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row ">
                    <div className="col-lg-7 mb-2 ">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Birth Help</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                        The hospital's Birth Help unit is specifically designed to provide comprehensive support to pregnant women around the clock. It is staffed by a team of experts, including Infertility Specialists, who are dedicated to ensuring a wonderful pregnancy and birthing experience for women.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}></span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        The unit offers various services such as pregnancy care coordination, which involves full-time assistance and guidance for pregnant women. This includes counseling, diet recommendations, exercise plans, and addressing any other concerns that may arise during pregnancy. The aim is to provide holistic care and support to expectant mothers throughout their pregnancy journey.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                        If you have any specific questions or need more information about a particular aspect of the hospital's services, feel free to ask!
                        </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-4 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='logo-site (1).png' alt=''></img>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Birth_help