import React from 'react'

const THIRD_TRIMESTER = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Third Trimester</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Third Trimester</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What Is the Third Trimester?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                            The third trimester is the last phase of your pregnancy. It lasts from weeks 29 to 40, or months 7, 8, and 9. During this trimester, your baby grows, develops, and starts to change position to get ready for birth.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>New fetal development during third trimester</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>In the third trimester, your baby keeps growing. By the end, a full-term baby usually is between 19 and 21 inches long and between 2.6 and 3.6 kgs.</p>

                        <p style={{ textAlign: 'justify' }}>Your baby begins to turn itself head-down to get ready for delivery. At week 36, the baby’s head should begin to move into your pelvic area, also called lightening. It will stay in this down-facing position for the last 2 weeks of your pregnancy.</p>
                        <p style={{ textAlign: 'justify' }}>Your baby develops in other important ways in the third trimester. During this phase, it’s able to:</p>
                        <ul style={{ textAlign: 'justify' }}>
                            <li>Open its eyes and see</li>
                            <li>Hear</li>
                            <li>Suck on its thumb</li>
                            <li>Cry</li>
                            <li>Smile</li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="3rd-trimester3.png" alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}>Other developments includes as following:</p>
                <ul>
                    <li>Your baby’s brain continues to develop.</li>
                    <li>Its lungs and kidneys mature</li>
                    <li>It gains muscle tone and about 16% body fat. </li>
                    <li>The bones at the top of its skull are soft to ease delivery. </li>
                    <li>Most babies have blue eyes at this stage, and they’ll stay that color until a few days or weeks after they’re born.</li>
                    <li>It also has nails on its toes and reaching to the ends of its fingers.</li>
                    <li></li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Changes in mother during third trimester of pregnancy:</span>
                </h4 >
                <ul style={{ textAlign: 'justify' }}>
                    <li>Abdominal achiness.</li>
                    <li>Backache.</li>
                    <li>Bleeding: Some light bleeding toward the end of your pregnancy might be a sign that labor is starting.</li>
                    <li>Braxton-Hicks contractions: You might start to feel mild contractions, which are warm-ups to prepare your uterus for the real labor to come. Braxton-Hicks contractions often aren't as intense as real labor contractions, but they may feel a lot like labor and can eventually progress to it. One main difference is that real contractions gradually get closer and closer together -- and more intense. If you're red in the face and out of breath after your contractions, or they're coming regularly, call your doctor.</li>
                    <li>Breast enlargement and leaking.</li>
                    <li>Vivid dreams.</li>
                    <li>Clumsiness.</li>
                    <li>Discharge. You might see more vaginal discharge during the third trimester. If the flow is heavy enough to soak through your panty liners, call your doctor.</li>
                    <li>Fatigue.</li>
                    <li>Frequent urination.</li>
                    <li>Heartburn and constipation.</li>
                    <li>Hemorrhoids.</li>
                    <li>Sciatica.</li>
                    <li>Spider and varicose veins.</li>
                    <li>Stretch marks.</li>
                    <li>Swelling.</li>
                    <li>Weight gain.</li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Emergency symptoms that needs immediate medical attention</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Any of these symptoms could be a sign that something is wrong with your pregnancy. Don't wait for your regular prenatal visit to talk about it. Call your doctor right away if you experience:
                </p>
                <ul>
                    <li>Severe abdominal pain or cramps</li>
                    <li>Severe nausea or vomiting</li>
                    <li>Bleeding</li>
                    <li>Severe dizziness</li>
                    <li>Pain or burning during urination</li>
                    <li>Rapid weight gain (more than 6.5 pounds per month) or too little weight gain</li>
                </ul>

            </div >
        </>
    )
}

export default THIRD_TRIMESTER