import React from 'react'

const Baby_News = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>baby news</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        {/* <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Baby News</span>
                        </h1> */}
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 4th week</span>
                        </h4 >
                        <p style={{textAlign:'justify'}}>In weeks 4 to 5 of early pregnancy, the embryo grows and develops within the lining of your womb. The outer cells reach out to form links with your blood supply. The inner cells form into 2, and then later into 3 layers. Each of these layers will grow to be different parts of your baby's body:</p>
                        <ul>
                            <li>The inner layer becomes the breathing and digestive systems, including the lungs, stomach, gut and bladder</li>
                            <li>The middle layer becomes the heart, blood vessels, muscles and bones</li>
                            <li>The outer layer becomes the brain and nervous system, the eye lenses, tooth enamel, skin and nails</li>

                        </ul>

                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 5th week</span>
                        </h4 >
                        <p style={{textAlign:'justify'}}>Your baby's nervous system is already developing, and the foundations for its major organs are in place. At this stage, the embryo is around 2mm long. The heart is forming as a simple tube-like structure. Your baby already has some of its own blood vessels and blood begins to circulate. A string of these blood vessels connects you to your baby and will become the umbilical cord. At the same time, the embryo's outer layer of cells develops a groove and folds to form a hollow tube called the neural tube. This will become your baby's brain and spinal cord. </p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="side-view-mother.jpg" alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 7th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By 7 weeks, the embryo has grown to about 10mm long from head to bottom. This measurement is called the crown-rump length. The brain is growing rapidly and this results in the head growing faster than the rest of the body. The embryo has a large forehead, and the eyes and ears continue to develop.</p>
                <p style={{textAlign:'justify'}}>The inner ears start to develop, but the outer ears on the sides of the head will not appear for a couple more weeks. The limb buds start to form cartilage, which will develop into the bones of the legs and arms.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 8th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By the time you're 8 weeks pregnant, your baby is called a fetus, which means offspring. The legs are getting longer. The different parts of the leg are not properly distinct yet. It'll be a bit longer before the knees, ankles, thighs and toes develop. The fetus is still inside its amniotic sac and the placenta is continuing to develop, forming structures that help attach the placenta to the wall of the womb.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 9th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby's face is slowly forming. The eyes are bigger and more obvious and have some colour (pigment) in them. There's a mouth and a tongue with tiny taste buds. The hands and feet are developing – ridges identify where the fingers and toes will be, although they have not separated out yet. The major internal organs, such as the heart, brain, lungs, kidneys and gut, continue developing. At 9 weeks of pregnancy, your baby has grown to about 22mm long from head to bottom.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 10th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The ears are starting to develop on the sides of your baby's head, and the ear canals are forming inside the head. If you could look at your baby's face, you'd be able to see an upper lip and 2 tiny nostrils in the nose. The jawbones are developing and already contain all the future milk teeth. The heart is now fully formed. It beats 180 times a minute – that's 2 to 3 times faster than your own heart.   </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 12th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Just 12 weeks after your last period, the fetus is fully formed. All the organs, muscles, limbs and bones are in place, and the sex organs are well developed. From now on, the baby has to grow and mature. It's too early for you to be able to feel the baby's movements yet, although they'll be moving quite a bit.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 13th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby weighs around 25g. Your baby's ovaries or testes are fully developed inside their body, and the genitals are forming outside their body. Vocal cords form and the fetus’s large head begins to grow proportionate to its body. </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 14th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>At 14 weeks, the baby is about 85mm long from head to bottom. Around now, the baby begins to swallow little bits of amniotic fluid, which pass into the stomach. The kidneys start to work and the swallowed fluid passes back into the amniotic fluid as urine. The fetus’s skin starts to thicken and fine hair begins to grow. It can start bringing its fingers to its mouth and turn its head. External genitals are fully developed and fingerprints begin to form.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 15th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Around this time, your baby will start to hear – they may hear muted sounds from the outside world and any noises your digestive system makes, as well as the sound of your voice and heart. The eyes also start to become sensitive to light. Even though your baby's eyes are closed, they may register a bright light outside your tummy.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 16th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The muscles of the baby's face can now move and the beginnings of facial expressions appear. Your baby cannot control these yet. The nervous system continues to develop, allowing the muscles in your baby's limbs to flex. Around this time, your baby's hands can reach each other – they can form a fist and hold each other when they touch. </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 17th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By the time you're 17 weeks pregnant, your baby is growing quickly and now weighs around 150g.</p>
                <p style={{textAlign:'justify'}}>The eyebrows and eyelashes are beginning to grow. Your baby's eyes can move now, although the eyelids are still shut, and the mouth can open and close. The lines on the skin of the fingers are now formed, so the baby already has their own individual fingerprints. Fingernails and toenails are growing, and the baby has a firm hand grip. </p>

                

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 18th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The baby moves around quite a bit, and may respond to loud noises from the outside world, such as music. You may not feel these movements yet, especially if this is your first pregnancy. If you do, they'll probably feel like a soft fluttering or rolling sensation. The fetus may have a sleep-wake cycle, and loud noises may wake the fetus if it’s asleep.  </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 20th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By 20 weeks, your baby's skin is covered in a white, greasy substance called vernix. It's thought this helps protect the skin during the many weeks in the amniotic fluid. The fetus’s nails grow towards the end of its fingers. The area of the brain responsible for its five senses begins to develop. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 22th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby has a pattern of sleeping and waking, which will not necessarily be the same as yours. When you're in bed at night, feeling relaxed and trying to sleep, your baby may be wide awake and moving about. The fetus’s grasp is getting stronger and it can touch its ears and the umbilical cord. It can hear your heartbeat, your stomach rumble and your breathing. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 23rd week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby's lungs are not yet able to work properly, but they are practicing breathing movements to prepare for life outside the womb. Your baby gets all their oxygen from you via the placenta and will do so until they're born.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 24th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By the time you're 24 weeks pregnant, the baby has a chance of survival if they are born. Most babies born before this time cannot live because their lungs and other vital organs are not developed enough. The care that can now be given in baby (neonatal) units means more and more babies born early do survive. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 26th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The baby's eyelids open for the first time around now and they will soon start blinking. It's       not until some weeks after the birth that your baby's eyes become the color they will stay. The fetus makes melanin, the substance that gives skin and eyes their color. The fetus’s lungs start to make surfactant, a substance that helps it breathe after birth. </p>
                

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 27th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By now your baby's heart rate will have slowed to around 140 beats per minute. This is still considerably faster than your own heart rate. Your baby's brain, lungs and digestive system are formed but not fully mature and are still developing. The fetus can open its eyes and blink. It also has eyelashes.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 28th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By 28 weeks, your baby weighs around 1 kg and is perfectly formed. The baby's heartbeat can now be heard through a stethoscope. Your partner may even be able to hear it by putting an ear to your bump, but it can be difficult to find the right place. Your baby keeps putting on weight, as more and more fat appears under the skin. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 29th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby continues to be very active at this stage, and you'll probably be aware of lots of movements. There's no set number of your baby's movements you should feel each day – every pregnancy is different. You should be aware of your baby's own pattern of movements. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 31st week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby's lungs are developing rapidly, but your baby would not be fully able to breathe on their own until about 36 weeks. The fetus can process more information and stimuli. You can probably notice more distinct patterns in when it’s awake and when it’s asleep. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 32 week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By about 32 weeks, the baby is usually lying with their head pointing downwards, ready for birth. This is known as cephalic presentation. If your baby is not lying head down at this stage, it's not a cause for concern – there's still time for them to turn. The amount of amniotic fluid in your uterus is increasing, and your baby is still swallowing fluid and passing it out as urine.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 33rd week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By 33 weeks of pregnancy, the baby's brain and nervous system are fully developed. The fetus’s bones are hardening, with the exception of its brain, which needs to be soft to descend the birth canal. </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 34th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby's bones are continuing to harden, apart from the skull bones. These will stay soft and separated until after the birth to make the journey through the birth canal easier. The bones can move gently and slide over each other so the head can be born safely while still protecting the brain. The vernix that protects the fetus’s skin starts to get thicker.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}> Your’s baby development in this 35th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Your baby is curled up in the uterus now, with legs bent up towards their chest. There's little room to move about, but they'll still change position, so you'll still feel movements and be able to see them on the surface of your bump. If your baby is a boy, his testicles are beginning to descend from his abdomen into his scrotum.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 36th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>By 36 weeks, your baby's lungs are fully formed and ready to take their first breath after the birth. The digestive system is fully developed and your baby will be able to feed if they're born now. The fetus loses its lanugo and it has hair on its head.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 37th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>At 37 weeks, your pregnancy is considered full-term. The average baby weighs around 3-4 kg by now. Your baby is ready to be born, and you'll be meeting them some time in the next few weeks. Your baby's gut (digestive system) now contains meconium – the sticky green substance that will form your baby's first stool after birth. It may include bits of the lanugo (fine hair) that covered your baby earlier in pregnancy.</p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 38th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>In the last weeks, some time before birth, the baby's head should move down into your pelvis. When your baby's head moves down like this, it's said to be "engaged". When this happens, you may notice your bump seems to move down a little. Sometimes the head does not engage until labor starts. </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 39th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The fine hair (lanugo) that covered your baby's body is now almost all gone, although some babies may have small patches of it when they're born. Because of the hormones in your body, the baby's genitals may look swollen when they're born, but they'll soon settle down to their normal size.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Your’s baby development in this 40th week</span>
                </h4 >
                <p style={{textAlign:'justify'}}>The fetus is full-term and ready to meet the world! The fetus is about 18 to 20 inches long. Call the hospital/doctor if you notice any signs of labor.</p>
            </div >
        </>
    )
}

export default Baby_News