import Carousel from 'react-bootstrap/Carousel';
import React from 'react'
import Testmonialss from './Testmonialss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './App.css'
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Home = () => {

    return (

        <>
            <HelmetProvider>
                <Helmet>

                    <meta charSet="utf-8" />
                    <title>Help Hospital Guntur</title>
                    <meta property="og:title" content="Help Hospital Guntur" />
                    <meta property="og:site_name" content="Help Hospital Guntur" />
                    <meta property="og:url" content="http://helphospital.in/" />
                    <meta property="og:description"
                        content="At Help Hospital, integrated healthcare is the primary focus, with modern facilities and innovative patient management at the forefront." />
                    {/*            
            <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
                </Helmet>
            </HelmetProvider>
            <div className='TopMargin'>
                <Carousel className=" homeclass" variant='dark' indicators={false} controls={false} pause={false} >
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="./Banner.png"
                            alt="First slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web_1_3.png"
                            alt="Second slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src="web_3_4.png"
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>


                {/* <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" data-bs-interval="100">
      <img src="web_2_3.png" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item" data-bs-interval="2000">
      <img src="web_2_3.png" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="web_2_3.png" class="d-block w-100" alt="..."/>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div> */}

            </div>
            <div className='fluid-container' style={{ backgroundColor: 'red' }}>
                <div className='container'>
                    <div className='row'>
                        <center>
                            <div className='col'>
                                <p style={{ color: 'white' }} className='mt-2'><span ><b><a href='https://helphospital.in/' style={{ color: 'white' }}>Help Hospital</a></b></span> :  4/5, Arundelpet, Guntur, AP</p>
                            </div>
                        </center>
                    </div>
                </div>
            </div>


            <div data-aos="flip-left" data-aos-duration="2000" className="container mt-5 mb-2 PARASET" >
                <div className="row">
                    {/* <div class="col-lg-4 mb-5" style={{ textAlign: 'center', paddingTop: '80px' }}>
                        <img className="img-thumbnail shadow-lg p-1 mb-5 bg-body rounded" style={{ width: "310px", height: '350px' }} src='hospital.png' alt=''></img>
                    </div> */}

                    <div className="col mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Help Hospital In Guntur</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            At Help Hospital, integrated healthcare is the primary focus, with modern facilities and innovative patient management at the forefront. One of the unique features of the hospital is the advanced technology <b>Health ATM (Any Time Monitoring)</b>, which allows patients to be continuously monitored even from the comfort of their homes.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            The hospital's Birth Help unit is specifically designed to provide comprehensive support to pregnant women around the clock. It is staffed by a team of experts, including Infertility Specialists, who are dedicated to ensuring a wonderful pregnancy and birthing experience for women.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            The unit offers various services such as pregnancy care coordination, which involves full-time assistance and guidance for pregnant women. This includes counseling , diet recommendations, exercise plans, and addressing any other concerns that may arise during pregnancy.The aim is to provide holistic care and support to expectant mothers throughout their pregnancy journey.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            If you have any specific questions or need more information about a particular aspect of the hospital's services, feel free to ask!
                        </p>


                    </div>
                </div>
            </div>

            {/* heading..... */}
            <div>
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Centres Of Excellence</span>
                </h1>
            </div>



            {/* cards........ */}

            <div className="container text-center facility cards mb-3">
                <div className="row facilityRow" style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-3 mb-4 facilityColum" style={{ width: '280px', display: 'flex', height: '410px' }}>
                        <div className="card text-center pb-2 shadow " style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="logo-site (1).png" alt='' style={{ width: '200px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX' }}></img>
                                <h3 className="card-title">Birth Help</h3>
                                <p className="lead" style={{ color: 'black', fontSize: '16px' }}>First of it’s kind, Integrated, digital and comprehensive support to pregnant women round the clock</p>
                                <Link to='/Birth_help'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5 facilityColum  " style={{ width: '280px', display: 'flex', height: '400px' }}>
                        <div className="card text-center pb-2 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="atm.png" alt='' style={{ width: '200px', height: '160px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX' }}></img>
                                <h3 className="card-title" style={{ fontSize: '22px' }}>Any Time Monitoring</h3>
                                <p className="lead" style={{ color: 'black', fontSize: '16px' }}>Advanced and integrated health care program that takes care of patients at home and in between the hospital visits</p>
                                <Link to='/Any_Time_Monitoring'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5 facilityColum" style={{ width: '280px', display: 'flex', height: '410px' }}>
                        <div className="card  text-center pb-2 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="down 2.png" alt='' style={{ width: '200px', height: '150px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title" style={{ fontSize: '19px' }} >Centre For Infectious Diseases</h3>
                                <p className="lead" style={{ color: 'black', fontSize: '16px' }}>CID in first of its kind state of the microbiology unit established at help hospital Guntur under the eminent guidance of Dr. Kalyan Chakravarthi. </p>
                                <Link to='/BEST_CENTRE_FOR_INFECTIOUS_DISEASES'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>



                    <div className="col-lg-3 mb-5 facilityColum" style={{ width: '280px', display: 'flex', height: '410px' }}>
                        <div className="card  text-center pb-2 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="Gynecologist.jpg" alt='' style={{ width: '220px', height: '150px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title" >Gynecology</h3>
                                <p className="lead" style={{ color: 'black', fontSize: '16px' }}> Welcome to our esteemed hospital in Guntur, where we are committed to providing exceptional gynecology care for women of all ages. </p>
                                {/* It is focused on the health of the female reproductive system. */}
                                <Link to='/Best_Gynecology_Treatment_In_Guntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container text-center facility cards mb-5" data-aos="zoom-in-right" data-aos-duration="2000" >
                <div className="row facilityRow" style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <div className="col-lg-3 mb-5 facilityColum mt-3" style={{ width: '280px', display: 'flex', height: '410px' }}>
                        <div className="card text-center pb-2 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="Diabetes.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title">Diabetes</h3>
                                <p className="lead" style={{ color: 'black' }}>It is a metabolic disease that leads to high blood sugar.</p>
                                <Link to='/Best_Diabetes_Treatment_InGuntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5  facilityColum mt-3" style={{ width: '280px', display: 'flex', height: '360px', marginBottom: '80px' }}>
                        <div className="card text-center pb-4 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="laparoscopy.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title">Laparoscopy</h3>
                                <p className="lead" style={{ color: 'black' }}>Minimally invasive surgical procedure using small incisions and a camera.</p>
                                <Link to='/Best_LAPAROSCOPY_Treatement_in_Guntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5  facilityColum mt-5" style={{ width: '280px', display: 'flex', height: '360px', alignItems: 'center' }}>
                        <div className="card text-center pb-4 shadow" style={{ color: 'rgb(245, 41, 41)', height: '420px' }}>
                            <div className="card-body">
                                <img src="fever.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title">Fever</h3>
                                <p className="lead" style={{ color: 'black' }}>Elevated body temperature often caused by infection or inflammation.</p>
                                <Link to='/Best_Fever_hospital_inGuntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 mb-5 facilityColum mt-3 " style={{ width: '280px', display: 'flex', height: '360px', }}>
                        <div className="card text-center pb-4 shadow " style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="blood_pressure.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title">Blood Pressure</h3>
                                <p className="lead" style={{ color: 'black' }}>Blood Pressure is Measurement of force in arteries.</p>
                                <Link to='/Best_High_BP_Treatment_in_Guntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-lg-3 mb-5 facilityColum mt-3" style={{ width: '280px', display: 'flex', height: '360px' }}>
                        <div className="card  text-center pb-2 shadow" style={{ color: "rgb(245, 41, 41)", height: '420px' }}>
                            <div className="card-body">
                                <img src="endometriosis.jpg" alt='' style={{ width: '220px', height: '180px', backgroundColor: 'white', imageOrientation: 'initial', marginBottom: '2PX', borderRadius: '5px' }}></img>
                                <h3 className="card-title">Endometriosis</h3>
                                <p className="lead" style={{ color: 'black' }}>Chronic condition where endometrial tissue grows outside the uterus.</p>
                                <Link to='/Best_Endometriosis_Treatment_In_Guntur'><Button variant='danger' >Know More</Button></Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>



            {/* get to know our team of experience..... */}



            <div className='container mt-5' >
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Get To Know Our Team Of Experts</span>
                </h1>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>

            {/* another side image cards......get to know our team of experience */}

            <div className='container' data-aos="fade-up-right" data-aos-duration="3000" >
                <div className='row'>
                    <div className='col-md-6 '  >
                        <div className="card mb-3 " style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px' }}>
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src="Untitled-1gdfgdfgdf.png" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Dr. Nelluri Bharathi</h5>
                                        <p className="card-text mt-2" style={{ fontSize: '17px' }}>Hands with more than two 2 decades of experience. Dr Bharathi M.S, (OBG) is a Postgraduate from renowned institute, Manipal Academy of Higher Education and has risk Pregnancy management.
                                        </p>
                                        <Link to='/Dr_Bharathi'><button className='btn btn-danger mt-2'>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-md-6'>
                        <div className="card mb-3" style={{ border: '4px solid #fff', boxShadow: '0 0 20px #ccc', borderRadius: '10px' }}  >
                            <div className="row g-0">
                                <div className="col-md-4">
                                    <img src="1692169279105.jpg" className="img-fluid rounded-start" alt="..." />
                                </div>
                                <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title">Dr.Kalyan Chakravarthy Koganti</h5>
                                        <p className="card-text" style={{ fontSize: '15px' }}>Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education.
                                            Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK and is the 1st ID ( Infectious Diseases) specialist of our state.</p>
                                        <Link to='/Dr_Kalyan_Chakravarthy_Koganti'><button className='btn btn-danger'>Read More</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Graduation from Guntur Medical College and Post Graduation from Manipal Academy of Higher Education Awarded PG certificate in Infectious diseases from London School of Hygiene and Tropical Medicine, UK. 
             Dr. Nelluri Bharathi, qualified with a Bachelor in Medicine and a Bachelor of Surgery (MBBS) and completed her post Graduation at Manipal Academy of Higher Education. Dr.Nelluri Bharath holds experience of 13 years. */}
            {/* <div className='container' data-aos="fade-right" data-aos-duration="3000">
                


            </div> */}
            {/* <div className='container' data-aos="fade-left" data-aos-duration="3000">
                <div className='row'>
                    <div className='col'  >
                        <div class="card mb-3" >
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="..." class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h5 class="card-title">Card title</h5>
                                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='col' >
                        <div class="card mb-3" >
                            <div class="row g-0">
                                <div class="col-md-4">
                                    <img src="..." class="img-fluid rounded-start" alt="..." />
                                </div>
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h5 class="card-title">Card title</h5>
                                        <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                        <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>   */}


            {/* testmonials...... */}

            <div className='container' data-aos="zoom-in" data-aos-duration="3000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Testmonials</span>
                </h1>
                <p style={{ textAlign: 'center' }}>Our team of experienced and accomplished doctors always give their best in providing ethical, modern and evidence based patient-centric treatment and always ensure health and safety of our patients.</p>
            </div>
            <div data-aos="zoom-in-up" data-aos-duration="3000" >
                <Testmonialss />
            </div>



            {/* Events and news..... */}
            <div className='container mb-4' id="events" data-aos="zoom-in" data-aos-duration="2000">
                <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginTop: '30px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}> Latest Blogs</span>
                </h1>
            </div>
            <div className='container text-center' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '60px', justifyContent: 'center' }}>
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div className='col-md-4 col-sm-12  mb-2 mt-3'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="Healthy-eating-for-pregnancy-PIP-6.png" alt='' style={{ height: '180px', marginTop: '1%' }} />
                            <Card.Body>
                                <Card.Title>Foods To Be Taken During Pregnancy</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                {/* <Button variant="primary">Know More</Button> */}
                                <Link to='/FOODS_TO_BE_TAKEN_DURING_PREGNANCY'> Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 col-sm-12  mb-2 mt-3'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="postnatal-care.jpg" alt='' style={{ height: '200px', marginTop: '1%' }} />
                            <Card.Body>
                                <Card.Title>Postnatal Care</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                {/* <Button variant="primary">Know More</Button> */}
                                <Link to='/Postnatal_Care'> Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 col-sm-12  mb-2 mt-3'>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="side-view-mother.jpg" alt='' style={{ height: '200px', marginTop: '1%' }} />
                            <Card.Body>
                                <Card.Title>Baby News</Card.Title>
                                <Card.Text>

                                </Card.Text>
                                {/* <Button variant="primary">Know More</Button> */}
                                <Link to='/Baby_News'> Know More</Link>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            {/* Insurance.... */}

            {/* <div class="right-navigation py-5">
                <button class="r-n-title1" onClick={()=>setSlide(true)} >Insurance</button>
              { slide && <div class="sidenav" id="mySidenav" style={{ minHeight: '260px',backgroundColor: 'white', transition: 'all ease .3s',top:'0' ,padding: '5px',textAlign: 'center',borderRadius:'5px'}}>
                    <div class="loactios-close" style={{position:"initial"}} onClick={()=>setSlide(false)}>X</div>
                    <div class="location-info">
                        <div class="location-title" style={{marginTop:'-25px'}}>
                            Available Insurances
                        </div>
                        <div class="row py-3">
                            <div class="col-md-3 col-3">
                                <img src="ABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="FGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="FHPL.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="GD.png" alt="image" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img src="GH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="HI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="ICICI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="IFFCO.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img src="MA.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'100px'}}  src="MC.png" alt="image" />
                            </div>

                            {/* <div class="col-md-3 col-3">
                                <img src="MDI.png" alt="image" />
                            </div> */}

            {/* <div class="col-md-4 col-4">
                                <img style={{height:'70px',width:'70px'}}  src="MHI.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-3 col-3">
                                <img style={{height:'50px',width:'50px'}} src="NABH.png" alt="image" />
                            </div>
                            <div class="col-md-3 col-3">
                                <img src="RGI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="RHI.png" alt="image" />
                            </div>

                            <div class="col-md-3 col-3">
                                <img src="STAR.png" alt="image" />
                            </div>
                        </div>
                    </div>
                    <div class="location-info">
                        <div class="row">
                            <div class="col-md-4 col-4">
                                <img style={{height:'50px',width:'110px'}}  src="SW.png" alt="image" />
                            </div>
                            <div class="col-md-4 col-4">
                                <img src="UHC.png" alt="image" />
                            </div>

                            <div class="col-md-4 col-4">
                                <img src="VH.png" alt="image" />
                            </div>

                           
                        </div>
                    </div>
                </div>}
            </div> */}


            {/* * book appointment pop up* */}


        </>

    )

}

export default Home