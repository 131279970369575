import React from 'react'

const Guide_to_a_Healthy_Diabetic_Diet = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Diabetic Diet</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)"}}>Diabetic Diet</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Living with diabetes doesn't mean sacrificing flavor or enjoyment in your meals. In fact, a well-managed diabetic diet can contribute to better blood sugar control and overall health. Here's a concise guide to help you navigate the ins and outs of a healthy diabetic diet.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)"}}>Understanding the Basics</span>
                        </h4 >
                        <p>A diabetic diet is not about deprivation; it's about making informed and balanced choices to regulate blood sugar levels. Whether you have Type 1 or Type 2 diabetes, the key is managing the types and amounts of foods you eat.</p>
                        <ul>
                            <li>Carbohydrates: Focus on complex carbohydrates found in whole grains, fruits, and vegetables. Be mindful of portion sizes and consider the glycemic index of foods.</li>
                            <li>Proteins: Opt for lean protein sources like poultry, fish, tofu, and legumes. Protein can help stabilize blood sugar levels and keep you feeling full.</li>
                            <li>Fats: Choose heart-healthy fats such as avocados, nuts, and olive oil. Limit saturated and trans fats found in processed and fried foods.</li>
                        </ul>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='diabetic_Diet_image.png' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Building a Balanced Plate</span>
                </h4 >
                <p><b>Carbohydrates: The Right Way</b></p>
                <p>
                    When it comes to carbohydrates, choose whole grains like brown rice, quinoa, and oats. Include colorful vegetables and fresh fruits, but be mindful of portions. Consider using the plate method: fill half your plate with non-starchy vegetables, one-quarter with lean protein, and one-quarter with whole grains or starchy vegetables.
                </p>
                <p><b>Proteins for Sustained Energy</b></p>
                <p>Incorporate lean proteins into your meals to help maintain energy levels. Chicken, turkey, fish, tofu, and legumes are excellent choices. Remember that plant-based proteins, like beans and lentils, can be just as satisfying.</p>
                <p><b>Healthy Fats for Heart Health</b></p>
                <p>Embrace healthy fats in moderation. Avocados, nuts, seeds, and olive oil can add richness to your meals without spiking blood sugar levels. Avoid trans fats and limit saturated fats found in processed snacks and fatty meats.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Meal Planning Strategies</span>
                </h4 >
                <p><b>Balanced Meals and Smart Snacking</b></p>
                <p>Plan your meals with a combination of carbohydrates, proteins, and fats. Snack smartly with options like Greek yogurt, raw vegetables with hummus, or a handful of nuts. This can help stabilize blood sugar levels and prevent overeating during main meals.</p>
                <p><b>Gluten-Free and Vegetarian Options</b></p>
                <p>For those with gluten sensitivity, opt for gluten-free grains like quinoa and brown rice. If you follow a vegetarian or vegan diet, focus on plant-based proteins like tofu, tempeh, and legumes to meet your nutritional needs.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Lifestyle Factors</span>
                </h4 >
                <p>Exercise and Stress Management</p>
                <p>Regular physical activity is crucial for diabetes management. It helps improve insulin sensitivity and can contribute to better blood sugar control. Additionally, managing stress through techniques like meditation or yoga can positively impact your overall well-being.</p>
            </div>
        </>
    )
}

export default Guide_to_a_Healthy_Diabetic_Diet