import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'


const Blogs = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>blogs</h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/home">Home</a></li>
          </ul>
        </div>
      </div>
      <div className='container text-center mt-5 EventsCards' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '40px', justifyContent: 'center' }}>
        <div className='row text-center' style={{ justifyContent: 'center' }}>
         
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Folic-Acid-Tablets.jpg" alt='' />
              <Card.Body>
                <Card.Title>Folic Acid Supplementation and Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Folic_Acid_Supplementation_and_Pregnancy'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="ExerciseImage.png" alt='' />
              <Card.Body>
                <Card.Title>Exercise During Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Exercise_During_Pregnancy'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="weight-gain.png" alt='' />
              <Card.Body>
                <Card.Title>How much weight should you gain during pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/How_Much_Weight_should_Be_Gain_During_Pregnancy'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

        </div>


        {/* //second row blogs// */}

      </div>

      <div className='container text-center mt-5 EventsCards' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '40px', justifyContent: 'center' }}>
        <div className='row text-center' style={{ justifyContent: 'center' }}>
         
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Vaccine-image.png" alt='' />
              <Card.Body>
                <Card.Title>Vaccines During Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/VACCINES_DURING_PREGNANCY'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Flu-Vaccine.png" alt='' />
              <Card.Body>
                <Card.Title>Flu Vaccine During Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/FLU_VACCINE_DURING_PREGNANCY'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Tdap-vaccine.png" alt='' />
              <Card.Body>
                <Card.Title>TDAP Vaccine During Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/TDAP_VACCINE_DURING_PREGNANCY'> Know More</Link>
              </Card.Body>
            </Card>
          </div>


        </div>




      </div>

      {/* third row blogs */}
      <div className='container text-center mt-5 EventsCards' data-aos="zoom-in-right" data-aos-duration="2000" style={{ alignItems: 'center', paddingLeft: '40px', justifyContent: 'center' }}>
        <div className='row text-center' style={{ justifyContent: 'center' }}>
         
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="image (1).png" alt='' />
              <Card.Body>
                <Card.Title>PREECLAMPSIA</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/PREECLAMPSIA'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Midsection-Of-Pregnant-Woman-Checking-Diabetes_G_1155170325.png" alt='' />
              <Card.Body>
                <Card.Title>GESTATIONAL DIABETES</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Gestational_Diabetes'> Know More</Link>
              </Card.Body>
            </Card>
          </div>



          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="early-signs-of-pregnancy.png" alt=''/>
              <Card.Body>
                <Card.Title>Early Signs Of Pregnancy</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Early_signs_of_pregnancy'> Know More</Link>
              </Card.Body>
            </Card>
          </div>



          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="tips for pregnant.webp" alt='' />
              <Card.Body>
                <Card.Title>When To Do Pregnancy Kit Test At Home</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/When_to_do_pregnancy_kit_test_at_home'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="When-Do-You-Start-To-Look-Pregnant.png" alt=''  />
              <Card.Body>
                <Card.Title>When Does A Pregnancy Start ?</Card.Title>
                <Card.Text>

                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/When_does_a_pregnancy_start'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="1st-trimester1.png" alt='' style={{width:'98%',height:'210px',marginTop:'1%',marginLeft:'1%'}} />
              <Card.Body>
                <Card.Title>First Trimester</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/First_Trimester'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="2nd-trimester2.png" alt='' style={{width:'98%',marginTop:'1%'}} />
              <Card.Body>
                <Card.Title>Second Trimester</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/SECOND_TRIMESTER'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="3rd-trimester3.png" alt='' style={{width:'98%',marginTop:'1%'}} />
              <Card.Body>
                <Card.Title>Third Trimester</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/THIRD_TRIMESTER'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="post_partumCareImage.png" alt='' />
              <Card.Body>
                <Card.Title>Post-partum Care</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Post_partum_care'> Know More</Link>
              </Card.Body>
            </Card>
          </div>

          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="MOTHER_IMAGE1.png" alt='' style={{height:'200px',marginTop:'1%'}}  />
              <Card.Body>
                <Card.Title>Mother News</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Mother_News'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="side-view-mother.jpg" alt='' style={{height:'200px',marginTop:'1%'}}  />
              <Card.Body>
                <Card.Title>Baby News</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Baby_News'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="postnatal-care.jpg" alt='' style={{height:'200px',marginTop:'1%'}}  />
              <Card.Body>
                <Card.Title>Postnatal Care</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/Postnatal_Care'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
          

          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="Healthy-eating-for-pregnancy-PIP-6.png" alt='' style={{height:'180px',marginTop:'1%'}}  />
              <Card.Body>
                <Card.Title>Foods To Be Taken During Pregnancy</Card.Title>
                <Card.Text>
                
                </Card.Text>
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/FOODS_TO_BE_TAKEN_DURING_PREGNANCY'> Know More</Link>
              </Card.Body>
            </Card>
          </div>


          <div className='col-md-4 col-sm-12  mb-2 mt-3'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="WhatsApp Image 2023-10-03 at 10.00.38 AM1.jpeg" alt='' />
              <Card.Body>
                <Card.Title style={{fontSize:'15.5px'}}>"Revolutionizing Rural Healthcare: Dr. Kalyan Chakravarthy Leads the Way with AI-Driven Live ICU in Guntur"</Card.Title>
              
                {/* <Button variant="primary">Know More</Button> */}
                <Link to='/smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh'> Know More</Link>
              </Card.Body>
            </Card>
          </div>
       
         </div>
      </div>

    </>
  )
}

export default Blogs