import React from 'react'

const PREECLAMPSIA = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>PREECLAMPSIA</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is Preeclampsia?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Preeclampsia is a serious blood pressure condition that develops during pregnancy. People with preeclampsia often have high blood pressure(hypertension) and high levels of protein in the urine(proteinuria).
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Who gets Preeclampsia?</span>
                        </h4>


                        <p style={{ textAlign: 'justify' }}>
                            Preeclampsia may be more common in first-time mothers. Healthcare providers are not entirely sure why some people develop preeclampsia. Some factors that may put you at a higher risk are:
                        </p>
                        <ul>
                            <li>History of high blood pressure, kidney disease or diabete.</li>
                            <li>Expecting multiples.</li>
                            <li>Family history of preeclampsia.</li>
                            <li>Autoimmune conditions like lupus.</li>
                            <li>Obesity</li>
                        </ul>



                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '200px', borderRadius: '15px', width: '80%', boxShadow: 'grey' }} id="ServicesImG" src='image (1).png' alt=''></img>
                    </div>
                </div>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What are the symptoms?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Many people with preeclampsia do not have any symptoms. For those that do, some of the first signs of preeclampsia are high blood pressure, protein in the urine and retaining water (this can cause weight gain and swelling).
                </p>

                <p style={{ textAlign: 'justify' }}>
                    Other signs of preeclampsia include:
                </p>
                <ul>
                    <li>Headaches.</li>
                    <li>Blurry vision or light sensitivity.</li>
                    <li>Dark spots appearing in your vision.</li>
                    <li>Right side abdominal pain.</li>
                    <li>Swelling in your hands and face (edema).</li>
                    <li>Shortness of breath.</li>

                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What week of pregnancy does preeclampsia start?</span>
                </h4>

                <p style={{ textAlign: 'justify' }}>
                Preeclampsia typically occurs after 20 weeks of pregnancy, but it can come earlier. Most preeclampsia occurs at or near term (37 weeks gestation). Preeclampsia can also come after delivery (postpartum preeclampsia), which usually occurs between the first few days to one week after delivery. In rare cases, it begins weeks after delivery.
                                </p>
                                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How can I reduce my risk of getting preeclampsia?</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>For people with risk factors, there are some steps that can be taken prior to and during pregnancy to lower the chance of developing preeclampsia. These steps can include:</p>
                <ul>
                    <li>Losing weight if you have overweight/obesity (prior to pregnancy-related weight gain).</li>
                    <li>Controlling your blood pressure and blood sugar (if you had high blood pressure or diabetes prior to pregnancy).</li>
                    <li>Maintaining a regular exercise routine.</li>
                    <li>Getting enough sleep.</li>
                    <li>Eating healthy foods that are low in salt and avoiding caffeine.</li>
                </ul>                     
            </div>
        </>
    )
}

export default PREECLAMPSIA