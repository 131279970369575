import React from 'react'

const Early_signs_of_pregnancy = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Early Signs Of Pregnancy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Early Signs Of Pregnancy</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What are some of the early signs that I am pregnant?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        You might have signs or symptoms of pregnancy within a few weeks of becoming pregnant.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                        The most common symptom of early pregnancy is a missed period. If you have an irregular menstrual cycle it will be less obvious.
                        </p>
                        <p style={{ textAlign: 'justify' }}>You may notice physical changes such as:</p>
                        <ul>
                            <li>Morning sickness — nausea and/or vomiting that may come and go throughout the day</li>
                            <li>Sore breasts</li>
                            <li>An increase in breast size</li>
                            <li>Feeling tired</li>
                            <li>Dizziness</li>
                            <li>Food cravings</li>
                            <li>Needing to pass urine (wee) more often</li>
                            <li>Abdominal pain or bloating</li>
                        </ul>
                       
                

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='early-signs-of-pregnancy.png' alt=''></img>
                    </div>
                </div>
                <p style={{ textAlign: 'justify' }}>
                        You may experience several of these changes. It's also possible that you won't feel much different to usual.
                        </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Emotional changes during early stage of pregnancy:</span>
                        </h4 >
                <p style={{ textAlign: 'justify' }}>
                The hormonal changes in early pregnancy can also cause changes to your mood. You may feel emotional and cry more easily. These feelings are very common in early pregnancy.
                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What should I do if I think I'm pregnant?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                If you think you may be pregnant, you can check using a home pregnancy test. Home pregnancy tests are easy to use. You can buy them at a pharmacy or supermarket.
                </p>
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>My home pregnancy test is positive</span>
                </h4 >

                <ol>
                    <li>If your home pregnancy test is positive, you should see your doctor or visit a family planning clinic for a blood test.</li>
                    <li>The blood test can confirm your pregnancy by measuring a hormone called human chorionic gonadotropin (hCG).</li>
                    <li>Once they confirm that you are pregnant, your doctor will give you information about what comes next. They can give advice on the best ways to look after yourself during pregnancy.</li>
                </ol>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>My home pregnancy test is negative</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>If your home pregnancy test is negative, but you still think you may be pregnant, you can see your doctor for a blood test to check. Your symptoms may be due to something other than pregnancy.</p>
               

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Things to do during early stage of pregnancy:</span>
                </h4 >

                <ul>
                    <li>Avoid alcohol</li>
                    <li>Avoid cigarette smoke </li>
                    <li>Eat a healthy diet</li>
                    <li>Take a folic acid supplement</li>
                </ul>
           </div >
        </>
    )
}

export default Early_signs_of_pregnancy