import React from 'react'

const Gynecology = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>GYNECOLOGY TREATMENT IN GUNTUR</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>GYNECOLOGY</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to our esteemed hospital in Guntur, where we are committed to providing exceptional gynecology care for women of all ages. With a team of experienced gynecologists, advanced diagnostic technology, and a patient-centered approach, we strive to deliver comprehensive and personalized care for a wide range of gynecological conditions. In this blog post, we will explore the exceptional gynecology services offered at our hospital and explain why we are the trusted choice for women's healthcare in the region.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Expert Gynecological Services:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            At our hospital, we offer a comprehensive range of gynecological services to address the unique healthcare needs of women. Whether it's routine preventive care, diagnosis and treatment of gynecological conditions, or specialized gynecological surgeries, our skilled gynecologists are dedicated to providing the highest standard of care.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='Gynecologist.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Preventive Care and Well-Woman Exams:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    We believe in the importance of preventive care and regular well-woman examinations to maintain optimal gynecological health. Our gynecologists provide comprehensive screenings, including Pap smears, HPV testing, breast examinations, and pelvic exams. These preventive measures play a vital role in early detection and treatment of any potential issues, ensuring the best possible outcomes for our patients.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Diagnosis and Treatment of Gynecological Conditions:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Our hospital is equipped with advanced diagnostic technology, allowing our gynecologists to accurately diagnose a wide range of gynecological conditions. From menstrual disorders, pelvic pain, and urinary incontinence to polycystic ovary syndrome (PCOS) and endometriosis, our team provides expert guidance and individualized treatment plans to address each patient's specific needs.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Minimally Invasive Gynecological Surgeries:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    When surgical intervention is necessary, we specialize in performing minimally invasive gynecological surgeries. These advanced techniques, such as laparoscopy and hysteroscopy, offer numerous benefits, including smaller incisions, faster recovery, reduced pain, and minimal scarring. Our skilled gynecologists have extensive experience in performing these procedures, ensuring optimal outcomes and improved quality of life for our patients.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Women's Wellness and Counseling:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our hospital, we understand that women's health encompasses not only physical well-being but also emotional and mental wellness. We provide comprehensive women's wellness programs, including counseling services, nutritional guidance, and lifestyle recommendations. Our aim is to empower women with the knowledge and resources to make informed decisions about their health and well-being.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Conclusion:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our leading hospital in Guntur, we are dedicated to providing comprehensive gynecology care that addresses the unique healthcare needs of women. With our experienced gynecologists, state-of-the-art facilities, and patient-centered approach, we strive to ensure the highest level of care and comfort for every patient. Whether you require preventive care, diagnosis and treatment of gynecological conditions, or minimally invasive surgeries, we are here to support you on your journey to optimal gynecological health.
                </p>
            </div>
        </>
    )
}

export default Gynecology