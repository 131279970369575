import React from 'react'

const FOODS_TO_BE_TAKEN_DURING_PREGNANCY = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>FOODS TO BE TAKEN DURING PREGNANCY</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row ">
                    <div className="col-lg-7 mb-5">
                        {/* <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Mother News</span>
                </h1>
                <p style={{textAlign:'justify'}}><b>Dear </b></p> */}
                        <p style={{textAlign:'justify'}}>In pregnancy the most important thing to be taken care of is the diet of mother and the fetus. Following a strict and healthy nutrition helps in growth of the baby. </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Foods to be taken during pregnancy includes:</span>
                        </h4 >
                        <p style={{textAlign:'justify'}}><b>Whole fruits </b> — like apples, berries, oranges, mango, and bananas</p>
                        <p style={{textAlign:'justify'}}><b>Veggies </b> — like broccoli, sweet potatoes, beets, okra, spinach, peppers, and jicama</p>
                        <p style={{textAlign:'justify'}}><b>Proteins</b> — like lean meats and chicken, eggs, seafood, beans and lentils, nuts and seeds, and tofu</p>
                        <p style={{textAlign:'justify'}}><b>Low-fat or fat-free dairy</b> — like milk, yogurt, cheese, lactose-free dairy, and fortified soy beverages (soy milk) or soy yogurt</p>
                        <p style={{textAlign:'justify'}}><b>Oils</b> — like vegetable oil, olive oil, and oils in foods like seafood, avocado, and nuts</p>
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-2 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '400px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="pregnant-young-woman-white-t-shirt-bright-background_488220-18413.avif" alt=''></img>
                    </div>
                    <center>
                    <img className="mt-2 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{  borderRadius: '15px', boxShadow: 'grey',width: '70%', }} id="ServicesImG" src="Healthy-eating-for-pregnancy-PIP-6.png" alt=''></img>
                    </center>
                </div>
            </div >
        </>
    )
}

export default FOODS_TO_BE_TAKEN_DURING_PREGNANCY