import React from 'react'

const Indian_Diet_Plan_In_Pregnancy = () => {
    return (
        <>

            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Indian Diet Plan In Pregnancy</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/home">Home</a></li>
                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row ">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Indian Diet Plan In Pregnancy</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            A healthy, well-balanced diet in pregnancy is essential for the well-being of the mother-to-be and her baby. This is because whatever the female consumes during pregnancy is the main source of nutrition for the baby. A mother-to-be’s diet should contain a variety of healthy foods like fruits, vegetables, and proteins to provide the important nutrients and nourishment a baby requires for growth and development. When it comes to eating healthy in pregnancy, deciding which foods are the most beneficial for you and your baby’s health can be challenging.
                        </p>
                        <p>Wondering, what to eat during pregnancy. Below mentioned is the pregnancy food list that has all the necessary nutrients needed for your baby's proper growth.</p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='pregnancyDiet.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Indian Diet Chart And Meal Plan For Pregnancy</span>
                </h4 >
                <table className='tablemobile '>
                    <tr style={{ border: '1px solid black' }}>
                        <th>Week Days</th>
                        <th>Pre Breakfast Snack</th>
                        <th>Morning Breakfast</th>
                        <th>Mid Morning Snack</th>
                        <th>Lunch</th>
                        <th>Evening Snack</th>
                        <th>Dinner</th>
                    </tr>
                    <tr>
                        <td>Day 1</td>
                        <td>8-12 pieces of dry fruits including cashew nuts, almonds, and raisins.</td>
                        <td>Moongdal chilla-3 +pudina/coconut chutney-2 tsp</td>
                        <td>Blueberry shake (1 cup)</td>
                        <td>1.5 cup Mutton biryani + Cucumber onion Raita (½ cup)</td>
                        <td>Sweet potato salad (cooked with 200 gms of sweet potato, 1 pinch of chat masala, and 1 tsp lemon juice) +  light tea (1 cup)</td>
                        <td>Wheat dosa 3 + ½ cup bitter gourd sabji </td>
                    </tr>
                    <tr>
                        <td>Day 2</td>
                        <td>Homemade fruit juice with strawberries, pomegranate, guava, or oranges (1 glass)</td>
                        <td>Wheat dosa-4 + Tomato sabji (½ cup)</td>
                        <td>Vegetable soup made with brocolli and garlic (1 bowl)</td>
                        <td>1.5 cup rice + 1 piece of chicken + 1 cup broccoli sabji</td>
                        <td>Mixed fruit salad made of mangoes, bananas, and berries of your choice (1 medium-sized plate)</td>
                        <td>Moongdal chilla-3 +pudina/coconut chutney-2 tsp</td>

                    </tr>
                    <tr>
                        <td>Day 3</td>
                        <td>Banana milkshake (1 glass)</td>
                        <td>Veggie-rich sevai or upma (1 cup) + 2 medium-sized parathas</td>
                        <td>Kaddu ka soup or pumpkin soup (1 bowl)</td>
                        <td>1.5 cup Mutton biryani + Cucumber onion Raita (½ cup)</td>
                        <td>10-14 pieces of dry fruits</td>
                        <td>Multigrain toast (2 in no.) with two sunny side up eggs</td>

                    </tr>
                    <tr>
                        <td>Day 4</td>
                        <td>Carrot juice (1 glass)</td>
                        <td>Oatmeal (1 cup) + 2 boiled eggs	</td>
                        <td>Banana milkshake (1 glass)</td>
                        <td>1.5 cup rice, 1 piece mutton + 1 bowl masoor daal</td>
                        <td>Mixed fruit salad made of mangoes, bananas, and berries of your choice (1 medium-sized plate)</td>
                        <td>Veggie-rich poha (1 cup) + 2 moong dal chillas</td>

                    </tr>
                    <tr>
                        <td>Day 5</td>
                        <td>1 plain glass of milk</td>
                        <td>Veggie-rich poha (1 cup) + 2 moong dal chillas</td>
                        <td>Tamatar or tomato soup (1 bowl)</td>
                        <td>1.5 cup chicken biryani + cucumber onion raita (½ cup)</td>
                        <td>Vegetable salad (1 plate) made with carrots, cucumber, and onions</td>
                        <td>Multigrain toast (2 in no.) with two sunny side up eggs</td>

                    </tr>
                    <tr>
                        <td>Day 6</td>
                        <td>Banana milkshake (1 glass)</td>
                        <td>Oatmeal (1 cup) + 2 boiled eggs</td>
                        <td>Kaddu or pumpkin soup (1 bowl)</td>
                        <td>1.5 cup rice, 1 piece mutton + 1 bowl masoor daal</td>
                        <td>10-14 pieces of dry fruits including cashew nuts, almonds, and raisins</td>
                        <td>Wheat dosa-4 + Tomato sabji (½ cup)</td>

                    </tr>
                    <tr>
                        <td>Day 7</td>
                        <td>1 plain glass of milk</td>
                        <td>Veggie-rich sevai or upma (1 cup) + 2 medium-sized parathas	</td>
                        <td>Peach milkshake (1 glass)</td>
                        <td>1.5 cup vegetable khichdi + 1 piece chicken + 1 small bowl of dahi</td>
                        <td>1 medium-sized avocado with 1 spoon of peanut butter</td>
                        <td>Veggie-rich poha (1 cup) + 2 moong dal chillas</td>

                    </tr>
                </table>
                <h4 className='mt-4' style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Foods And Beverages To Eat During Pregnancy </span>
                </h4 >
                <p><b>1.	Dairy Products: </b>Dairy products are rich in various nutrients like calcium and vitamins that support the baby’s development.
                </p>
                <p><b>2.	Legumes: </b>Legumes are rich in plant-based nutrients providing protein, fibre, iron, folate, and calcium, all of which are needed more during pregnancy.
                </p>
                <p><b>3.	Sweet Potatoes: </b>Sweet potatoes are high in a vitamin called beta carotene, essential for healthy fetal development.
                </p>
                <p><b>4.	Salmon: </b>Salmon is rich in an omega-3 fatty acid called docosahexaenoic acid (DHA), which is deficient in pregnancy and, therefore, needs to be supplemented.
                </p>
                <p><b>5.	Eggs: </b>Eggs are an excellent source of protein. They provide amino acids that the baby needs for development. Additionally, they contain more than a dozen beneficial vitamins and minerals, including choline.
                </p>
                <p><b>6.	Broccoli and dark, leafy greens: </b>Dark, leafy greens like broccoli are rich in vitamins A, C, B6, K, folate, and antioxidants. So, they ensure a good haemoglobin supply, promote healthy bones, and prevent skin problems.
                </p>
                <p><b>7.	Lean meat and proteins: </b>Lean meat is an excellent source of good-quality protein, which is beneficial for health during pregnancy.
                </p>
                <p><b>8.	Berries: </b>Berries contain a good amount of water, healthy carbohydrates, vitamin C, and fibres. In addition, they may help increase your nutrient and water intake.
                </p>
                <p><b>9.	Whole grains: </b>Whole grains are rich in minerals and vitamin B that the growing fetus needs to develop in almost every part of its body.
                </p>
                <p><b>10.	Avocados: </b>Avocados are high in many nutrients, including vitamin C, folate, and magnesium, all of which play a critical role in the baby's development.
                </p>
                <p><b>11.	Dry fruits: </b>Dry fruits are high in calcium, potassium, and zinc. Being rich in fibre, they help prevent constipation in pregnancy.
                </p>
                <p><b>12.	Fish liver oil: </b>Fish liver oils are rich in omega-3 fatty acids, the healthiest forms of fat. They reduce the chances of preterm delivery.
                </p>
                <p><b>13.	Water: </b>Drinking at least 8-11 glasses of water daily during pregnancy is essential. It helps in better digestion and maintains the protective fluid called amniotic fluid around the fetus.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Food And Beverages To Avoid During Pregnancy</span>
                </h4 >

                <p><b>1.	High mercury fish: </b>Consuming high mercury fish causes the substance (mercury) to accumulate in your bloodstream over a period of time. Excess mercury in the bloodstream could damage the baby’s developing nervous system and brain.
                </p>
                <p><b>2.	Undercooked or raw fish: </b>Raw fish is more likely to contain parasites, bacteria, or microorganisms than fully cooked fish. You do not want to expose your baby to those!
                </p>
                <p><b>3.	Undercooked, raw, and processed meat: </b>Processed meat is likely to contain listeria bacteria which can cause food poisoning and vomiting in pregnant females. Raw and undercooked meat is also likely to carry microorganisms, including bacteria that can give rise to sickness.
                </p>
                <p><b>4.	Raw eggs: </b>Raw eggs should be avoided in pregnancy as those can be host to disease-causing bacteria called salmonella, which can cause food poisoning, vomiting, and diarrhoea.
                </p>
                <p><b>5.	Organ meat: </b>Organ meat contains vitamin A, excess of which can cause congenital malformations in the baby and even miscarriage, especially in the first trimester of pregnancy.
                </p>
                <p><b>6.	Caffeine: </b>Caffeine is a stimulant that tends to increase your heart rate and blood pressure, both of which are detrimental in pregnancy. Also, caffeine can cross the placenta, the barrier between you and your baby.
                </p>
                <p><b>7.	Raw sprouts: </b>Raw sprouts flourish in warm and humid conditions, which are ideal for the growth of bacteria (like Salmonella and E. coli). So, consuming raw sprouts can make you sick.
                </p>
                <p><b>8.	Unwashed produce: </b>Consuming unwashed or contaminated food products in pregnancy can expose you to a harmful parasite called Toxoplasma gondii, which is abundant in undercooked meat and unwashed vegetables.
                </p>
                <p><b>9.	Unpasteurized milk, cheese, and fruit juice: </b>Raw, unpasteurized milk may contain bacteria like E.coli, Listeria, and Campylobacter jejuni, which can give rise to foodborne illnesses.
                </p>
                <p><b>10.	Alcohol: </b>Alcohol consumption in the first trimester of pregnancy can cause structural defects in the baby; that is, the baby may have abnormal facial features.
                </p>
                <p><b>11.	Processed junk foods: </b>Consuming processed junk foods during pregnancy can increase the amount of a toxic substance called acrylamide in the mother's body, which is harmful to the baby. This Indian diet plan for pregnancy may or may not suit everyone. In case of food allergies and complications, we can plan a personalized diet chart This Indian diet plan for pregnancy may or may not suit everyone. In case of food allergies and complications, we can plan a personalized diet chart.
                </p>
             

            </div>
        </>
    )
}

export default Indian_Diet_Plan_In_Pregnancy