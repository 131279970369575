import React from 'react'

const Role_Of_Laparoscopy_In_Treating_Infertility = () => {
    return (
        <>
            <div style={{ paddingTop: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Role Of Laparoscopy In Treating Infertility</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Role Of Laparoscopy In Treating Infertilityr</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Infertility is defined as the inability to conceive within one year of unprotected regular intercourse. Men and women have infertility resulting from tubal infections, ovulation disorders, endometriosis, cervical and uterine complications, and male factor infertility. According to the world health organization (WHO), the leading cause of infertility includes malnutrition, pelvic tuberculosis, and recurrent infections causing the blockage in tubes.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                            Doctors have adopted doing laparoscopies for infertility diagnosis and treatment. The procedure involves using a laparoscope to examine the reproductive system. This helps identify abnormalities in the system to determine the best treatment method. Your doctor can recommend laparoscopy in case of unexplained pelvic pain. Doctors don’t use the procedure in the first line of treatment since it only assesses problems that make conception difficult.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body" style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='role-of-laparoscopy-in-infertility-scaled.jpg' alt=''></img>
                    </div>
                </div>

                <p style={{ textAlign: 'justify' }}>
                    Laparoscopy is a non-invasive procedure that offers a panoramic and magnified view of the pelvic organs. A doctor can perform surgery during diagnosis. It has played a crucial role in diagnosing and treating pelvic abnormalities in females. It improves fertility through surgery in the uterus, ovaries, pelvic, peritoneum, and fallopian tubes. If you have unexplained infertility, your doctor may recommend a laparoscopy to check and correct problems in the reproductive organs.        </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What Causes Infertility?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>Many things can cause infertility, but for women, the major causes are:</p>
                
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>1.	Ovulation disorder</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Ovulation disorders inhibit releasing the egg in some women, while in others, ovulation only occurs in some cycles reducing the chances of conception. These disorders include
                </p>

                <ul>
                    <li>Premature ovarian failure- ovaries stop working at age 40</li>
                    <li>Polycystic ovary syndrome (PCOS)</li>
                    <li>Thyroid disorders, such as overactive or underactive thyroid gland</li>
                    <li>Must Read: Difference Between PCOS and PCOD</li>
                </ul>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>2.	Scarring From Surgery</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                    Pelvic or cervical surgery can damage and scar surrounding organs, including the uterus and fallopian tubes.
                </p>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>3.	Fibroids</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    These are non-cancerous growths that prevent attachment of the fertilized egg in the womb or block the fallopian tubes.
                </p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>4.	Endometriosis </span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    This is a medical condition where the tissue lining the womb grows in other parts, such as the ovaries resulting in damage.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>5.	Pelvic Inflammatory Disease (PID)</span>
                </h4>
                <p>PID is a bacterial infection of the female upper genital tract that can cause damage and scarring on the fallopian tubes.</p>
                <p>In males, the major cause of infertility is the malefactor, including:</p>
                <ul>
                    <li>Poor quality semen and low sperm count</li>
                    <li>Testicle disorders emanating from infection, surgery, and cancer</li>
                    <li>Ejaculation disorders that inhibit semen release </li>
                </ul>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Ways of Diagnosis for Infertility</span>
                </h4>
                <p>Doctors can use various methods to diagnose infertility, depending on your situation. </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>1.	Ovulation Testing</span>
                </h4>
                <p>You can do this procedure at home using an ovulation prediction kit to detect the amount of luteinizing hormone. It can also be done in the hospital through a blood test to check for progesterone hormone produced during ovulation.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>2.	Ovarian Reserve Testing</span>
                </h4>
                <p>This test determines the quality and quantity of eggs available for ovulation. Imaging techniques and blood tests may be used.</p>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>3.	Hysterosalpingography (HSG)</span>
                </h4>
                <p>This procedure involves the injection of x-ray contrast fluid in your uterus to check for abnormalities. The test checks the fluid flow from the uterus into the fallopian tubes. If there is a problem, the doctor does further testing.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>4.	Laparoscopy </span>
                </h4>
                <p>Laparoscopy is a non-invasive surgery where the doctor uses a laparoscope to view your internal reproductive organs. The doctor inserts the laparoscope through a small incision below the navel to view the uterus, ovaries, and fallopian tubes. This helps diagnose scarring, endometriosis, blockage of tubes, and ovary abnormalities.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>5.	Imaging</span>
                </h4>
                <p>A doctor uses an ultrasound to check for uterine and fallopian tube infections. </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Benefits of Laparoscopy Procedure in Treating Infertility</span>
                </h4>
                <p>1.	Laparoscopies for infertility can be beneficial if you have scarring or blockage in the fallopian tubes. This disease is hard to diagnose and treat using other methods of diagnosis. A laparoscope helps the doctor to view and correct blocked or scarred tubes to enhance conception.
                </p>
                <p>2.	While other methods of diagnosis can identify fibroids and endometriosis, laparoscopy is the only sure method of ascertaining the presence of these abnormalities. Doctors can only diagnose mild endometriosis and scarring in the uterus through laparoscopy.</p>
                <p>3.	Pelvic pathologies can go undetected, resulting in recurrent in vitro fertilization failures. Laparoscopy helps detect and treat these abnormalities, increasing the chances of conception.
                </p>
                <p>4.	Removal of pelvic adhesions and endometrial tissue through laparoscopic surgery enhances natural conception. If you have unexplained infertility, a laparoscopy can help you determine its cause.</p>
                <p>5.	Laparoscopy is less invasive compared to open surgery. You only take a day to recover, and you can go back to your normal routine within a week. Additionally, it’s less painful and has minimal blood loss since it involves small incisions.</p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>How Laparoscopy is Performed</span>
                </h4>
                <p>Like other surgeries, you must fast for 8 hours before surgery. The doctor uses general anesthesia to make you unconscious during the operation.
                    The surgeon makes a minor incision below the navel using a syringe. The doctor pumps carbon dioxide through the syringe to elevate the abdominal wall from internal organs.
                </p>
                <p>They insert a laparoscope through the incision when the gas is enough. Checkout the reason for gas problems. The viewing device has a camera connected to an external screen. The connection gives clear and magnified images of the internal organs.
                    The doctor uses the screen to view the ovaries, fallopian tubes, and uterus. It’s easy to identify abnormalities such as Scarring, minor endometriosis, pelvic adhesions, cysts, and fibroids in the reproductive system.
                </p>
                <p>The doctor injects a dye from the cervix for a clear view of blockage in the fallopian tubes. The dye also helps detect an ectopic pregnancy.
                    If the procedure includes surgery, the doctor makes other incisions to allow entry of other clinical tools. The screen guides the doctor on where to direct the tools to the target organ. This procedure can be used for blocked tubes, removing fibroids, scar tissue, cyst, and endometrial tissue.
                </p>
                <p>When the doctor is satisfied, he removes the laparoscope and other instruments. The carbon dioxide is let out through a syringe. Then, he closes the incisions through stitches or clips and applies a dressing. You have to take antibiotics and pain relievers to avoid pain and infections. </p>
               

            </div>
        </>
    )
}

export default Role_Of_Laparoscopy_In_Treating_Infertility