import React from 'react'

const SEPSIS_CRITICAL_CARE = () => {
  return (
    <>
    <div style={{height:'120px'}}></div>
    <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex',backgroundColor:"rgb(245, 41, 41)",}}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1>SEPSIS & CRITICAL CARE</h1>
          </div>
          <ul classname="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>
           
          </ul>
        </div>
      </div>


      <div class="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div class="row">
          <div class="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Sepsis And Critical Care</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
            Sepsis is a clinical syndrome of life-threatening organ dysfunction caused by a dysregulated response to infection. In septic shock, there is critical reduction in tissue perfusion; acute failure of multiple organs, including the lungs, kidneys, and liver, can occur.
            </p>
            <p style={{ textAlign: 'justify' }}>
            Sepsis remains a major cause of mortality in intensive care. The past 15 years has seen a more uniform, world-wide approach to the management of sepsis, severe sepsis and septic shock with improved survival. Recognizing the early symptoms and signs of sepsis are key: the confused, hypoxic, hypotensive patient with pyrexia, tachycardia, tachypnoea and leucocytosis. Examination must include search for a source of infection and early drainage or debridement. Next to take appropriate cultures, give fluids and broad-spectrum antibiotics. If the picture does not improve over the next 6 hours step-up the treatment to include urine output monitoring, blood gases for base excess, lactate, haemoglobin and glucose. 
            </p>
            
          </div>
          <div  style={{ textAlign: 'center' }} class="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px',borderRadius:'10px' ,width:'350px',boxShadow:'grey' }} id="ServicesImG" src='' alt=''></img>
          </div>
        </div>
        <p style={{ textAlign: 'justify' }}>
        These will guide the management of vasopressors, insulin, fluids, transfusion and bicarbonate. If the hypotension persists (septic shock) the patient should be moved to intensive care. The most recent recommendations include the withdrawal of starch based colloids, dobutamine in place of dopamine and a higher threshold for the use of steroids. This should be instituted within 24 hours of the start of sepsis. Advanced care includes mechanical ventilation using the ARDSnet protocol. Prevention by screening, stopping cross infection and appropriate use of antibiotics remains the first priority.
        </p>
        <p style={{ textAlign: 'justify' }}>
        <b>Keywords: </b>Intensive care, sepsis, septic shock, severe sepsis.
        </p>
      </div>
    </>
  )
}

export default SEPSIS_CRITICAL_CARE