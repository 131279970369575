import React from 'react'
import {
    MDBFooter,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBIcon 
  } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const footer = () => {
  return (
    <>
    
    <MDBFooter bgColor='light' className='container-fluid text-center text-lg-start text-muted mt-5 mb-2'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom' style={{backgroundColor:'rgb(245, 41, 41)',color:'snow'}}>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div style={{color:'whitesmoke'}}>
          <a href='https://www.facebook.com/helphospitalguntur' className='me-4 text-reset' style={{color:'whitesmoke'}}>
            <MDBIcon  fab icon="bi bi-facebook"  />
            
          </a>
        
          <a href='https://www.google.com/search?q=kalyan+chakravarthy+koganti&rlz=1C1JJTC_enIN1028IN1028&sxsrf=AB5stBhh9P3-CAwbcyiM2FdmuOfHycPADA%3A1691214474711&ei=iuLNZJCGK4WX4-EPx4mS4Aw&ved=0ahUKEwiQ84Pw6MSAAxWFyzgGHceEBMwQ4dUDCA8&uact=5&oq=kalyan+chakravarthy+koganti&gs_lp=Egxnd3Mtd2l6LXNlcnAiG2thbHlhbiBjaGFrcmF2YXJ0aHkga29nYW50aTIHECMYigUYJzIEECMYJzIEECMYJzICECYyCBAAGIoFGIYDMggQABiKBRiGA0ipEFCoA1jiCnABeACQAQCYAfIBoAG7B6oBBTAuNi4xuAEDyAEA-AEBwgIIEAAYogQYsAPiAwQYASBBiAYBkAYB&sclient=gws-wiz-serp' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-google' />
          </a>
          <a href='https://www.instagram.com/helphospital_guntur/' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-instagram' />
          </a>
          <a href='https://www.linkedin.com/in/dr-kalyan-chakravarthy-koganti-4b6ab0232?originalSubdomain=in' className='me-4 text-reset'>
            <MDBIcon fab icon='bi bi-linkedin' />
          </a>
          <a href='https://www.youtube.com/channel/UCekR_-Ad9pYEbsumIDBFJsA' className='me-4 text-reset'>
            <MDBIcon  fab icon='bi bi-youtube' />
          </a>
        </div>
      </section>

      <section className=''style={{backgroundColor:'rgb(245, 41, 41)',color:'snow'}}>
        <MDBContainer className='text-center text-md-start ' >
          <MDBRow className='' style={{Color:'rgb(245, 41, 41)',paddingTop:'30px'}}>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <img src='footerImage.jpg'  style={{width:'250px',height:'150px',borderRadius:'10px'}} alt=''></img>
              <p style={{ textAlign:'center',marginTop:'10px' }}>
              Help hospital is one of its kind health care institutions where an integrated health care is given the top most priority. 
              </p>
            </MDBCol>

            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
              
                <Link to='Best_Diabetes_Treatment_InGuntur' className='text-reset'>
                  Diabetes
                </Link>
              </p>
              <p>
                <Link to='Best_Fever_hospital_inGuntur' className='text-reset'>
                  Fever
                </Link>
              </p>
              <p>
                <Link to='Best_LAPAROSCOPY_Treatement_in_Guntur' className='text-reset'>
                  Laparoscopy
                </Link>
              </p>
              <p>
                <Link to='Best_Obstetrics_Treatment_In_Guntur' className='text-reset'>
                  Obstetrics
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <Link to='Best_Gynecology_Treatment_In_Guntur' className='text-reset'>
                  Gynecologist
                </Link>
              </p>
              <p>
                <Link to='Best_Pcos_pcod_Treatment_in_guntur' className='text-reset'>
                  Pcos/Pcod
                </Link>
              </p>
              <p>
                <Link to='Best_High_BP_Treatment_in_Guntur' className='text-reset'>
                  Blood Pressure
                </Link>
              </p>
              <p>
                <Link to='Best_Endometriosis_Treatment_In_Guntur' className='text-reset'>
                  Endometriosis
                </Link>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary'className='me-2' />
                <i className="bi bi-house-door-fill"></i><span> 4/5, Arundelpet, Guntur, Andhra Pradesh 522002</span>
               
              </p>
              <p>
                <MDBIcon color='secondary'  style={{color:'white'}} className='me-3' />
                <i className="bi bi-envelope-at-fill" style={{fontSize:'14px'}}> helphospitalinfo@gmail.com</i>
               
              </p>
              <p>
                <MDBIcon color='secondary'  className='me-3' /> 
                <i className="bi bi-telephone-fill">+ 9000 100 108</i>
              </p>
              <p>
                <MDBIcon color='secondary'  className='me-3' /><i className="bi bi-phone-fill"> + 70326 48222</i>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBRow style={{border:'1px solid white'}}>
            <p className='text-center mt-3 mb-3'>Designed By - <span className='DartLink'><a href='http://www.dartmarketing.in/' >Dart Marketing Solutions</a></span></p>
            
          </MDBRow>
      </section>

      
    </MDBFooter>


  
    
    </>
  )
}

export default footer