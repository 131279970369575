import React from 'react'

const Obstetrics = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>OBSTETRICS TREATMENT IN GUNTUR</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>OBSTETRICS</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to our obstetrics hospital in Guntur, where we are dedicated to providing exceptional care and support to expectant mothers throughout their pregnancy journey. Our team of skilled obstetricians, compassionate nurses, and state-of-the-art facilities create an environment that ensures safe and joyful motherhood. In this blog post, we will explore the unique features and services offered by our obstetrics hospital in Guntur.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Expert Obstetricians and Care Team:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            At our hospital, we have a highly qualified and experienced team of obstetricians who are committed to the well-being of both the mother and the baby. They possess extensive knowledge and expertise in managing normal pregnancies, high-risk pregnancies, and complex obstetric cases. Our care team, including nurses and midwives, provide personalized attention and emotional support, making every mother feel comfortable and confident during this crucial phase.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body rounded" style={{ height: '300px', borderRadius: '10px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>State-of-the-Art Facilities:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    We understand the significance of advanced medical facilities in ensuring optimal care for expectant mothers. Our obstetrics hospital is equipped with state-of-the-art technology and infrastructure, enabling accurate diagnosis, monitoring, and treatment. We have well-equipped labor and delivery rooms, advanced fetal monitoring systems, and dedicated operating theaters for cesarean sections, if required. These facilities ensure that our patients receive the best possible care during childbirth.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Prenatal Care and Education:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    We emphasize the importance of prenatal care and education to ensure a healthy pregnancy and successful delivery. Our hospital offers comprehensive prenatal care, including regular check-ups, prenatal screenings, ultrasounds, and nutritional guidance. Additionally, we conduct informative sessions and classes to educate expectant mothers about various aspects of pregnancy, labor, and postpartum care. We believe that well-informed mothers are better prepared to make informed decisions regarding their health and that of their babies.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>High-Risk Pregnancy Management:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    In cases of high-risk pregnancies, our obstetrics hospital is well-equipped to handle complex situations. Our team of experts closely monitors high-risk pregnancies, offering specialized care and interventions when necessary. We collaborate with other medical specialties, such as maternal-fetal medicine, neonatology, and genetics, to ensure comprehensive management and the best possible outcomes for both mother and baby.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Postpartum Care and Support:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Our commitment to the well-being of new mothers extends beyond childbirth. We provide comprehensive postpartum care and support, ensuring a smooth transition to motherhood. Our dedicated team offers lactation support, guidance on newborn care, and emotional support during this transformative period. We also conduct postpartum check-ups to monitor the mother's recovery and address any concerns she may have.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Conclusion:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Choosing the right obstetrics hospital is crucial for a safe and fulfilling pregnancy journey. At our obstetrics hospital in Guntur, we strive to create a nurturing environment that prioritizes the health and happiness of both mother and baby. With expert medical care, advanced facilities, and a compassionate team, we are dedicated to ensuring safe and joyful motherhood for every patient. Trust us to be your partner in this remarkable journey.
                </p>
            </div>
        </>
    )
}

export default Obstetrics