import React from 'react'

const Endometriosis = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1> ENDOMETRIOSIS TREATMENT IN GUNTUR</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>ENDOMETRIOSIS</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to our specialized hospital in Guntur, where we provide comprehensive care and support for women suffering from endometriosis. With a dedicated team of experienced healthcare professionals, advanced diagnostic tools, and a patient-centered approach, we aim to empower women by effectively managing the symptoms and providing relief from this chronic condition. In this blog post, we will explore endometriosis, its impact on women's health, and the exceptional care available at our hospital.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Understanding Endometriosis:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Endometriosis is a chronic condition in which the tissue similar to the lining of the uterus (endometrium) grows outside the uterus, commonly affecting the pelvic region. It can cause debilitating pain, menstrual irregularities, fertility issues, and other related symptoms. At our hospital, we recognize the complexity of endometriosis and provide comprehensive care to address its impact on a woman's quality of life.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='endometriosis.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Expert Diagnosis and Treatment:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Our hospital offers advanced diagnostic tools and techniques to accurately diagnose endometriosis. Our experienced gynecologists specialize in evaluating symptoms, conducting physical examinations, and utilizing imaging studies, such as ultrasound or MRI, to identify endometrial lesions. We work closely with each patient to develop an individualized treatment plan that may include medication, hormonal therapy, pain management strategies, or minimally invasive surgeries.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Minimally Invasive Laparoscopic Surgery:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    For women with severe endometriosis or fertility concerns, our skilled surgeons specialize in performing minimally invasive laparoscopic surgery. This approach allows for precise visualization and removal of endometrial lesions, while minimizing scarring, pain, and recovery time. Our surgeons are well-versed in advanced techniques to preserve fertility and restore pelvic health, providing personalized surgical solutions to meet each patient's unique needs.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Multidisciplinary Approach:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    We believe in a holistic and multidisciplinary approach to endometriosis care. Our team includes gynecologists, pain management specialists, fertility experts, psychologists, and nutritionists who collaborate to provide comprehensive treatment plans. This integrated approach ensures that all aspects of the patient's physical and emotional well-being are addressed, leading to improved outcomes and a better quality of life.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Patient Education and Support:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our hospital, we understand the importance of patient education and support in managing endometriosis. We provide in-depth information about the condition, its impact, and available treatment options. Our healthcare professionals take the time to address patient concerns, answer questions, and provide emotional support throughout the treatment journey. Additionally, we offer support groups and counseling services to foster a sense of community and empower women with endometriosis.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Continuum of Care:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Our commitment to our patients extends beyond diagnosis and treatment. We offer comprehensive follow-up care, including regular check-ups, ongoing pain management, fertility support, and hormonal management. We strive to build long-lasting relationships with our patients, ensuring they receive the necessary care and support at every stage of their endometriosis journey.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Conclusion:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our specialized hospital in Guntur, we are dedicated to providing comprehensive care and support for women living with endometriosis. Through expert diagnosis, advanced treatment options, and a multidisciplinary approach, we aim to alleviate symptoms, improve quality of life, and empower women to live their lives to the fullest. If you or a loved one is affected by endometriosis, we invite you to explore our services and take the first step towards finding relief and support.
                </p>
            </div>
        </>
    )
}

export default Endometriosis