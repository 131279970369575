
import './App.css';
import Header from './Header';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Home from './Home';
import Footer from './footer';
import Dr_Kalyan_Chakravarthy_Koganti from './Dr_Kalyan_Chakravarthy_Koganti';
import Dr_Bharathi from './Dr_Bharathi';
import SEPSIS_CRITICAL_CARE from './SEPSIS_CRITICAL_CARE';
import General_Medicine from './General_Medicine';
import Best_Fever_hospital_inGuntur from './Best_Fever_hospital_inGuntur';
import Best_High_BP_Treatment_in_Guntur from './Best_High_BP_Treatment_in_Guntur';
import Best_Diabetes_Treatment_InGuntur from './Best_Diabetes_Treatment_InGuntur';
import Pcos_pcod from './Pcos_pcod';
import Obstetrics from './Best_Obstetrics_Treatment_In_Guntur';
import Gynecology from './Best_Gynecology_Treatment_In_Guntur';
import IVF_Fertility_Treatment from './IVF_Fertility_Treatment';
import Endometriosis from './Best_Endometriosis_Treatment_In_Guntur';
import Contact from './Contact';
import Any_Time_Monitoring from './Any_Time_Monitoring';
import Birth_help from './Birth_help';
import Insurance_Available_In_Hospital from './Insurance_Available_In_Hospital';
import Best_LAPAROSCOPY_Treatement_in_Guntur from './Best_LAPAROSCOPY_Treatement_in_Guntur';
import ScrollToTop from "react-scroll-to-top";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import BEST_CENTRE_FOR_INFECTIOUS_DISEASES from './BEST_CENTRE_FOR_INFECTIOUS_DISEASES';
import Blogs from './Blogs';
import Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet from './Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet';
import Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh from './smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh';
import How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive from './How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive';
import Indian_Diet_Plan_In_Pregnancy from './Indian_Diet_Plan_In_Pregnancy';
import Role_Of_Laparoscopy_In_Treating_Infertility from './Role_Of_Laparoscopy_In_Treating_Infertility';
import Best_General_Medicine_Hospitals_In_Guntur from './Best_General_Medicine_Hospitals_In_Guntur';
import Infertility_When_to_Seek_Medical_Help from './Infertility_When_to_Seek_Medical_Help';
import Guide_to_a_Healthy_Diabetic_Diet from './Guide_to_a_Healthy_Diabetic_Diet';
import Dengue_Fever_Treatment_In_Guntur from './Dengue_Fever_Treatment_In_Guntur';
import Folic_Acid_Supplementation_and_Pregnancy from './Folic_Acid_Supplementation_and_Pregnancy';
import How_Much_Weight_should_Be_Gain_During_Pregnancy from './How_Much_Weight_should_Be_Gain_During_Pregnancy';
import Exercise_During_Pregnancy from './Exercise_During_Pregnancy';
import VACCINES_DURING_PREGNANCY from './VACCINES_DURING_PREGNANCY';
import FLU_VACCINE_DURING_PREGNANCY from './FLU_VACCINE_DURING_PREGNANCY';
import TDAP_VACCINE_DURING_PREGNANCY from './TDAP_VACCINE_DURING_PREGNANCY';
import PREECLAMPSIA from './PREECLAMPSIA';
import Images from './Images';
import Videos from './Videos';
import Gestational_Diabetes from './Gestational';
import Early_signs_of_pregnancy from './Early_signs_of_pregnancy';
import When_to_do_pregnancy_kit_test_at_home from './When_to_do_pregnancy_kit_test_at_home';
import When_does_a_pregnancy_start from './When_does_a_pregnancy_start';
import First_Trimester from './First_Trimester';
import SECOND_TRIMESTER from './SECOND_TRIMESTER';
import THIRD_TRIMESTER from './THIRD_TRIMESTER';
import Post_partum_care from './Post_partum_care';
import Mother_News from './Mother_News';
import Baby_News from './Baby_News';
import Postnatal_Care from './Postnatal_Care';
import FOODS_TO_BE_TAKEN_DURING_PREGNANCY from './FOODS_TO_BE_TAKEN_DURING_PREGNANCY';





function App() {
  return (
    <>


      <BrowserRouter>
        <Header />
        <HelmetProvider>
          <Helmet>

            <meta charSet="utf-8" />
            <title>Best Hospital in Guntur </title>
            <meta property="og:title" content="Help Hospital Guntur" />
            <meta property="og:site_name" content="Help Hospital Guntur" />          
            <meta property="og:description"
              content="At Help Hospital, integrated healthcare is the primary focus, with modern facilities and innovative patient management at the forefront." />
          
            {/* <meta property="og:image" content="http://helphospital.in/Help%20LOGO%20(1).png" /> */}
          </Helmet>
        </HelmetProvider>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Dr_Kalyan_Chakravarthy_Koganti" element={<Dr_Kalyan_Chakravarthy_Koganti />} />
          <Route path="/Dr_Bharathi" element={<Dr_Bharathi />} />
          <Route path="/SEPSIS_CRITICAL_CARE" element={<SEPSIS_CRITICAL_CARE />} />
          <Route path="/General_Medicine" element={<General_Medicine />} />
          <Route path="/Best_Fever_hospital_inGuntur" element={<Best_Fever_hospital_inGuntur />} />
          <Route path="/Best_High_BP_Treatment_in_Guntur" element={<Best_High_BP_Treatment_in_Guntur />} />
          <Route path="/Best_Diabetes_Treatment_InGuntur" element={<Best_Diabetes_Treatment_InGuntur />} />
          <Route path="/Best_Pcos_pcod_Treatment_in_guntur" element={<Pcos_pcod />} />
          <Route path='Best_LAPAROSCOPY_Treatement_in_Guntur' element={<Best_LAPAROSCOPY_Treatement_in_Guntur />} />
          <Route path="/Best_Obstetrics_Treatment_In_Guntur" element={<Obstetrics />} />
          <Route path="/Best_Gynecology_Treatment_In_Guntur" element={<Gynecology />} />
          <Route path="/IVF_Fertility_Treatment" element={<IVF_Fertility_Treatment />} />
          <Route path="/Best_Endometriosis_Treatment_In_Guntur" element={< Endometriosis />} />
          <Route path="/Any_Time_Monitoring" element={< Any_Time_Monitoring />} />
          <Route path="/Birth_help" element={< Birth_help />} />
          <Route path="/BEST_CENTRE_FOR_INFECTIOUS_DISEASES" element={< BEST_CENTRE_FOR_INFECTIOUS_DISEASES />} />
          <Route path="/Insurance_Available_In_Hospital" element={< Insurance_Available_In_Hospital />} />
          <Route path="Blogs" element={<Blogs />} />
          <Route path="/Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet" element={<Fever_Fighting_Food_Your_Guide_to_an_Effective_Fever_Diet />} />
          <Route path="/Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh" element={<Smart_icu_launched_at_phc_in_Chilakaluripeta_andhra_pradesh />} />
          <Route path="/How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive" element={<How_to_Get_Pregnant_Best_Tips_to_Help_You_Conceive />} />
          <Route path='/Indian_Diet_Plan_In_Pregnancy' element={<Indian_Diet_Plan_In_Pregnancy/>}/>
          <Route path="/Role_Of_Laparoscopy_In_Treating_Infertility" element={<Role_Of_Laparoscopy_In_Treating_Infertility />} />
        <Route path='/Best_General_Medicine_Hospitals_In_Guntur' element={<Best_General_Medicine_Hospitals_In_Guntur/>}/>
        <Route path="/Infertility_When_to_Seek_Medical_Help" element={<Infertility_When_to_Seek_Medical_Help/>} />
        <Route path='/Guide_to_a_Healthy_Diabetic_Diet' element={<Guide_to_a_Healthy_Diabetic_Diet/>}/>
        <Route path='/Dengue_Fever_Treatment_In_Guntur' element={<Dengue_Fever_Treatment_In_Guntur/>}/>
        <Route path='/Folic_Acid_Supplementation_and_Pregnancy' element={<Folic_Acid_Supplementation_and_Pregnancy/>}/>
        <Route path="/Exercise_During_Pregnancy" element={<Exercise_During_Pregnancy/>} />
        <Route path='/How_Much_Weight_should_Be_Gain_During_Pregnancy' element={<How_Much_Weight_should_Be_Gain_During_Pregnancy/>}/>
        <Route path='/VACCINES_DURING_PREGNANCY' element={<VACCINES_DURING_PREGNANCY/>}/>
        <Route path='/FLU_VACCINE_DURING_PREGNANCY' element={<FLU_VACCINE_DURING_PREGNANCY/>}/>
       <Route path='/TDAP_VACCINE_DURING_PREGNANCY' element={<TDAP_VACCINE_DURING_PREGNANCY/>}/>
       <Route path='/PREECLAMPSIA' element={<PREECLAMPSIA/>}></Route>
       <Route path='/Images' element={<Images/>}/>
       <Route path='/Videos' element={<Videos/>}/>
       <Route path='/Gestational_Diabetes'element={<Gestational_Diabetes/>}/>
       <Route path='/Early_signs_of_pregnancy' element={<Early_signs_of_pregnancy/>}/>
       <Route path='/When_to_do_pregnancy_kit_test_at_home' element={<When_to_do_pregnancy_kit_test_at_home/>}/>
       <Route path='/When_does_a_pregnancy_start' element={<When_does_a_pregnancy_start/>}/>
       <Route path='/First_Trimester' element={<First_Trimester/>}/>
       <Route path='/SECOND_TRIMESTER' element={<SECOND_TRIMESTER/>}/>
       <Route path='/THIRD_TRIMESTER' element={<THIRD_TRIMESTER/>}/>
       <Route path='/Post_partum_care' element={<Post_partum_care/>}/>
       <Route path='/Mother_News' element={<Mother_News/>}/>
       <Route path='/Baby_News' element ={<Baby_News/>}/>
       <Route path='/Postnatal_Care' element={<Postnatal_Care/>}/>
       <Route path='/FOODS_TO_BE_TAKEN_DURING_PREGNANCY' element={<FOODS_TO_BE_TAKEN_DURING_PREGNANCY/>}/>
        </Routes>

        {/* <div className='right-navigation'>
          <Link to='Insurance_Available_In_Hospital' className='insurance_float'><img className="whatsapp_float" src='insu6.webp' alt=''></img>Insurance</Link>
        </div> */}
        <div className="GoTop"  >
          <ScrollToTop smooth style={{ backgroundColor: 'rgb(245, 41, 41)', bottom: '10px', width: '35px', height: '35px' }} />
        </div>
        <div className='sbuttons'>
        <Link className='sbutton youtube' to='https://www.youtube.com/@kalyankoganti/about' title='Youtube'><i className="bi bi-youtube"></i></Link>
               <Link className='sbutton facebook' to='https://www.facebook.com/helphospitalguntur' title='Facebook'><i className="bi bi-facebook"></i></Link>
              <Link className='sbutton linkedin' to='https://www.linkedin.com/in/dr-kalyan-chakravarthy-koganti-4b6ab0232?originalSubdomain=in' title='LinkedIn'><i className="bi bi-linkedin"></i></Link>

              <Link className='sbutton instagram' to='https://www.instagram.com/helphospital_guntur/' title='Instagram' ><i className="bi bi-instagram"></i></Link>

        </div>
        <Footer />
      </BrowserRouter>




    </>
  );
}

export default App;
