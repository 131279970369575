import React from 'react'

const Fever = () => {
  return (
    <>
      <div style={{ height: '120px' }}></div>
      <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
        <div className="col text-center strip1" style={{ color: 'white' }}>
          <div className="row mt-4" >
            <h1 style={{ textTransform: 'uppercase' }}>Best Fever hospital in Guntur </h1>
          </div>
          <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
            <li ><a href="/Home">Home</a></li>

          </ul>
        </div>
      </div>


      <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
        <div className="row">
          <div className="col-lg-7 mb-2">
            <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
              <span style={{ color: "rgb(245, 41, 41)" }}>Fever</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Fever is when there is an elevation in human body’s temperature considerably beyond the normal range of 36–37° Centigrade (98–100° Fahrenheit). It is a common medical sign. Help Hospital is the Best Fever hospital in Guntur which is established by first fever specialist of AP, Dr Kalyan Chakravarthy Koganti.
            </p>
            <p style={{ textAlign: 'justify' }}>
              Symptoms Of Fever Include:
            </p>
            <ul>
              <li>Shivering.</li>
              <li>Loss of appetite.</li>
              <li>Feeling cold.</li>
              <li>Dehydration.</li>
              <li>Irritability.</li>
            </ul>
            {/* <p style={{ textAlign: 'justify' }}>
            Symptoms Of Fever Include:
            </p>
            <ul>
              <li>Hyperalgesia.</li>
              <li>Weakness/ lethargy.</li>
              <li>Sweating.</li>
              <li>Sleepiness.</li>
            </ul> */}
            <p style={{ textAlign: 'justify' }}>
              Check Out These Blogs About Fever:
            </p>
            <ul>
              <li>Best Fever Hospital In Guntur</li>
              <li>Fever Symptoms, Causes, Care & Treatment</li>

            </ul>

          </div>
          <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
            <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='fever.jpg' alt=''></img>
          </div>
        </div>


        {/* <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
          <span style={{ color: "rgb(245, 41, 41)" }}>What Are the 5 Types of Fevers?</span>
        </h4 > */}
        <p style={{ textAlign: 'justify' }}>
          <b>How To Break A Fever</b>
        </p>
        {/* <p style={{ textAlign: 'justify' }}>
        Assess your symptoms by checking your temperature. Fever is present if your temperature is 100.4°F (38°C) or higher.
        </p> */}
        <p style={{ textAlign: 'justify' }}>
          Take proper rest.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Don’t allow your body to be dehydrated. Drink water or diluted juice which replenishes fluids lost through sweating.
        </p>
        <p style={{ textAlign: 'justify' }}>
          You should not give antibiotics without consultation with your best doctors for fever in Guntur
        </p>
        <p style={{ textAlign: 'justify' }}>
          Stay cool. Unless you have chills, remove extra layers of blankets and clothing.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Cold compress can be used to make you more comfortable. Ice cube baths, Cold baths or rubs or alcohol baths can be dangerous and should be avoided.
        </p>
        <p style={{ textAlign: 'justify' }}>
          Whatever may be the thermometer’s reading, if you have any concerns, consult your doctor for fever treatment at best clinic for fever in Guntur.
        </p>
      </div>
    </>
  )
}

export default Fever