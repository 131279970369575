import React from 'react'

const SECOND_TRIMESTER = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>Second Trimester</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Second Trimester</span>
                        </h1>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>What is the second trimester of pregnancy?</span>
                        </h4 >
                        <p style={{ textAlign: 'justify' }}>
                        When you enter your second trimester, you are around 14 weeks pregnant. This middle trimester will last from week 14 to the end of week 27.
                        </p>
                        <p style={{ textAlign: 'justify' }}>
                        For many people, this is the best part of pregnancy because the morning sickness and fatigue of their first trimester fade into the past. Often, any anxiety that went with your first trimester also starts to diminish at this point. You’ll start to feel your fetus move by the end of this trimester, and you might begin to settle into your pregnancy and enjoy it more. Of course, it’s important to remember that pregnancy is different for everyone. Some people never experience negative symptoms like morning sickness in their first trimester. Others might continue to feel sick well into their second trimester of pregnancy.
                        </p>
                       
                      
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="2nd-trimester2.png" alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>How does my baby develop during the second trimester of pregnancy?</span>
                        </h4 >
                <p style={{ textAlign: 'justify' }}>
                Your fetus will go through many changes during your second trimester of pregnancy. During this trimester, the fetus starts to look more like a child — with its facial features aligning, and its fingers and toes becoming well-defined. By month four, the fetus will actually have eyelids, eyebrows, eyelashes, nails and hair. The fetus will also be able to stretch, make faces and even suck on its thumb.
                </p>
                <p style={{ textAlign: 'justify' }}>
                In the last few weeks of the second trimester, the fetus can also hear you. If you talk to your growing belly, you might notice movement in response.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>What happens to my body during the second trimester of pregnancy?</span>
                </h4 >
                <p style={{ textAlign: 'justify' }}>
                The fetus isn’t the only one growing and changing during your second trimester. You'll notice several changes in your own body during this time. Your uterus — the place where the fetus grows during pregnancy — continues to stretch. This organ will expand throughout your pregnancy as the fetus gets larger. After pregnancy, your uterus will return to its pre-pregnancy size.
                </p>
                <p style={{ textAlign: 'justify' }}>
                However, your uterus isn’t the only thing growing during the second trimester either. You’ll start gaining weight and might start developing the tell-tale enlarged belly of a pregnant person. Don’t worry if this takes time to develop. Everyone is different, and no two bodies will look exactly the same during pregnancy.
                </p>
                <p style={{ textAlign: 'justify' }}>
                You might also feel or develop a few new symptoms of pregnancy during your second trimester, including:
                </p>
                <ul>
                    <li>An increased appetite.</li>
                    <li>An achy body.</li>
                    <li>Some swelling in your hands, feet and ankles.</li>
                    <li>Some stretch marks.</li>
                    
                </ul>
            
               
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Will the symptoms of first trimester continues?</span>
                </h4 >

                <p style={{ textAlign: 'justify' }}>
                If you experienced morning sickness during your first trimester, it’s likely fading away now. The uncomfortable symptoms of early pregnancy (nausea and extreme fatigue, for example) don’t typically continue into your second trimester. This is one reason why many people consider their second trimester of pregnancy to be the best part of pregnancy.
                </p>

           </div >
        </>
    )
}

export default SECOND_TRIMESTER