import React from 'react'

const IVF_Fertility_Treatment = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>IVF FERTILITY TREATMENT IN GUNTUR</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>
                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>IVF FERTILITY TREATMENT</span>
                        </h1>
                        <p style={{ textAlign: 'justify' }}>
                            Welcome to our esteemed hospital in Guntur, where we offer advanced and compassionate IVF (In Vitro Fertilization) fertility treatment for couples struggling with infertility. With a team of skilled fertility specialists, state-of-the-art facilities, and a patient-centered approach, we are committed to helping couples achieve their dreams of starting or expanding their family. In this blog post, we will delve into the world of IVF fertility treatment, explaining its benefits, procedures, and why our hospital is the trusted choice for couples seeking fertility solutions.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Understanding IVF Fertility Treatment:</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            IVF is a highly effective assisted reproductive technology that involves the fertilization of eggs with sperm outside the body in a laboratory. It offers hope to couples facing various fertility challenges, including infertility due to fallopian tube blockage, male factor infertility, ovulatory disorders, or unexplained infertility. Our hospital is at the forefront of IVF treatment, employing the latest techniques and protocols to maximize success rates.
                        </p>

                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src='ivf.jpg' alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Comprehensive Fertility Evaluation:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our hospital, we recognize the importance of a comprehensive fertility evaluation to determine the underlying causes of infertility. Our experienced fertility specialists conduct thorough assessments, including medical history reviews, hormonal evaluations, semen analysis, and diagnostic tests. This helps us develop personalized treatment plans tailored to the specific needs of each couple.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Personalized IVF Treatment Plans:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Our fertility specialists understand that every couple's fertility journey is unique. We provide individualized IVF treatment plans that consider the couple's medical history, age, specific fertility challenges, and personal preferences. Our aim is to optimize the chances of successful conception and a healthy pregnancy while prioritizing the emotional well-being of our patients throughout the process.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Advanced IVF Laboratory and Techniques:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our hospital, we have a state-of-the-art IVF laboratory equipped with cutting-edge technology. Our experienced embryologists utilize advanced techniques, including Intracytoplasmic Sperm Injection (ICSI), Preimplantation Genetic Testing (PGT), and Blastocyst culture, to enhance the success rates of IVF procedures. We ensure the highest standard of care and quality control to maximize the chances of a successful pregnancy.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Comprehensive Support and Counseling:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    Going through fertility treatment can be emotionally challenging. We provide comprehensive support and counseling services to couples throughout their IVF journey. Our compassionate team offers guidance, empathy, and emotional support, helping couples navigate the ups and downs of fertility treatment and fostering a positive and hopeful environment.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Success Stories and Testimonials:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    To inspire hope and confidence, we regularly share success stories and testimonials from couples who have achieved their dream of parenthood through our IVF treatment. These real-life experiences highlight the effectiveness of our services and the exceptional care provided by our team, instilling trust in prospective patients.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Conclusion:</span>
                </h4>
                <p style={{ textAlign: 'justify' }}>
                    At our leading hospital in Guntur, we understand the emotional and physical challenges couples face when dealing with infertility. With our advanced IVF fertility treatment, personalized care, and compassionate support, we are dedicated to helping couples overcome infertility and achieve the joy of parenthood. If you are struggling with infertility, we invite you to explore our IVF services and take the first step toward realizing your dream of starting or expanding your family.
                </p>
            </div>
        </>
    )
}

export default IVF_Fertility_Treatment