
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import emailjs from 'emailjs-com'


const Header = () => {

  // bookappointment script 
  const form = useRef()
  const [data, updateData] = useState({
    Name: "",
    Email: '',
    Number: '',
    Subject: '',
    Message: ''
  })

  const { Name, Email, Number, Subject, Message } = data;

  const changeHandler = e => {
    updateData({ ...data, [e.target.name]: [e.target.value] })

  }
  const submitHandler = e => {
    e.preventDefault();

    emailjs.sendForm('service_e4unsfa', 'template_i2qlv5d', e.target, 'x9kx9l2tKiJht888k')
      .then((result) => {
        alert("Successfully Booked Your Appointment ")
        updateData({
          Name: "",
          Email: "",
          Number: "",
          Subject: '',
          Message: ''

        })

      }, (error) => {
        console.log(error.text);
      });

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);


  // Redirect links...
  const [links, setLinks] = useState(false);
  const [atm, setatm] = useState(false);
  const [dibts, setdibts] = useState(false);
  const [glmds, setglmds] = useState(false);
  const [bp, setbp] = useState(false);
  const [bh, setbh] = useState(false);
  const [pspd, setpspd] = useState(false);
  const [LPY, setLPY] = useState(false);
  const [obstcs, setobstcs] = useState(false);
  const [gyncts, setgyncts] = useState(false);
  const [ivf, setivf] = useState(false);
  const [endo, setendo] = useState(false);
  const [home, sethome] = useState(false);
  const [about, setabout] = useState(false);
  const [contact, setcontact] = useState(false);
  const [insurance, setinsurance] = useState(false);
  const [kalyan, setkalyan] = useState(false);
  const [bharathi, setbharathi] = useState(false);
  const [blogs,setBlogs] = useState(false);
  const [images,setImages] = useState(false);
  const [videos,setVideos] = useState(false);

  if (images) {
    return <div>
      <Header />
      <return to='/Images' />
    </div>
  }
  if (videos) {
    return <div>
      <Header />
      <return to='/Videos' />
    </div>
  }

  if (about) {
    return <div>
      <Header />
      <return to='/about' />
    </div>
  }

  if (blogs) {
    return <div>
      <Header />
      <return to='/Blogs' />
    </div>
  }


  if (bharathi) {
    return <div>
      <Header />
      <return to='/Dr_Bharathi' />
    </div>
  }
  if (contact) {
    return <div>
      <Header />
      <return to='/contact' />
    </div>
  }
  if (insurance) {
    return <div>
      <Header />
      <return to='/insurance' />
    </div>
  }
  if (kalyan) {
    return <div>
      <Header />
      <return to='/Dr_Kalyan_Chakravarthy_Koganti' />
    </div>
  }
  if (links) {
    return <div>
      <Header />
      <return to='/Best_Fever_hospital_inGuntur' />
    </div>
  }
  if (atm) {
    return <div>
      <Header />
      <return to='/Any_Time_Monitoring' />
    </div>
  }
  if (home) {
    return <div>
      <Header />
      <return to='/Home' />
    </div>
  }
  if (dibts) {
    return <div>
      <Header />
      <return to='/Best_Diabetes_Treatment_InGuntur' />
    </div>
  }
  if (glmds) {
    return <div>
      <Header />
      <return to='/General_Medicine' />
    </div>
  }
  if (bp) {
    return <div>
      <Header />
      <return to='/Best_High_BP_Treatment_in_Guntur' />
    </div>
  }
  if (bh) {
    return <div>
      <Header />
      <return to='/Birth_help' />
    </div>
  }
  if (pspd) {
    return <div>
      <Header />
      <return to='/Best_Pcos_pcod_Treatment_in_guntur' />
    </div>
  }

  if (LPY) {
    return <div>
      <Header />
      <return to='/Best_LAPAROSCOPY_Treatement_in_Guntur' />
    </div>
  }

  if (obstcs) {
    return <div>
      <Header />
      <return to='/Best_Obstetrics_Treatment_In_Guntur' />
    </div>
  }

  if (gyncts) {
    return <div>
      <Header />
      <return to='/Best_Gynecology_Treatment_In_Guntur' />
    </div>
  }
  if (ivf) {
    return <div>
      <Header />
      <return to='/IVF_Fertility_Treatment' />
    </div>
  }
  if (endo) {
    return <div>
      <Header />
      <return to='/Best_Endometriosis_Treatment_In_Guntur' />
    </div>
  }


  return (

    <>
      {/* <div className=''>
        <div className='row' style={{backgroundColor:'#2c2c2c'}}>
          <div className='col-sm-3'>
            <ul class="list-inline xs-text-center text-white " style={{ display: 'flex', }}>
              <li class="m-1 p-1"><a href="tel:9040108108" style={{ textDecoration: 'none' }} class="text-white"><i class="bi bi-telephone-fill"></i>9040-108-108</a></li>
              <li class="m-1 p-1"><a href="mailto:info@shrihospital.com" class="text-white"><i class="bi bi-envelope text-theme-colored"></i>
                info@shrihospital.com</a></li>
            </ul>
          </div>
          <div className='col-sm-3'>
          <a href="tel:08632213999" class="image01">
                <h5 class="covid ">Helpline :<span> 0863-2213-999 </span> </h5>
              </a>

          </div>
        </div>

      </div> */}
      {/* <div class="header-top bg-black-333 sm-text-center border-top-theme-color-3px p-0" style={{ backgroundColor: '#2c2c2c',height:'auto'}}>
        <div class="container-fluid" >
          <div class="row">
            <div class="col-sm-4 text-center" >
              <div class="widget no-border m-0 ">
                
                <ul class="list-inline xs-text-center text-white " style={{ display: 'flex',fontSize:'15px'}}>
                  <li class="m-0 "><a href="tel:9040108108"  class="text-white"><i class="bi bi-telephone-fill"></i>9040-108-108</a></li>
                  <li class="m-0 "><a href="mailto:info@shrihospital.com" class="text-white"><i class="bi bi-envelope text-theme-colored"></i>
                    info@shrihospital.com</a></li>
                </ul>
               
              </div>
            </div>
            <div class="col-sm-3">
              <center>
              <a href="tel:08632213999" class="image01">
                <h5 class="covid m-0" style={{color:'rgb(245, 41, 41)',fontSize:'19px'}}>Helpline :<span> 0863-2213-999 </span> </h5>
              </a>
              </center>
            </div>
            <div class="col-sm-3 ">
              <center>
                <a href="tel:9040108108" class="image01">
                  <h5 class="covid m-0" style={{color:'rgb(245, 41, 41)',fontSize:'19px'}}> Appointment  :<span> 9040108108 </span> </h5>
                </a>
              </center>
            </div>

            <div class="col-sm-2 ">
              <center>
              <a class="btn btn-colored btn-flat btn-theme-colored pb-10 m-0" style={{backgroundColor:'rgb(245, 41, 41)',color:'whitesmoke',marginTop:'0px'}} href="#" data-toggle="modal" data-target="#appointment">Appointment</a>
              </center>
            </div>
          </div>
        </div>
      </div> */}

      {/* <Navbar  sticky="top"  bg="light" expand="lg" className="NavStyles">
        <Container fluid>
          <Navbar.Brand href="#"> <img
            src="Help LOGO (2).png"
            width="200vh"
            height="80vh"
            className="d-inline-block align-top"
            alt="React Bootstrap logo" style={{ paddingLeft: '30px',paddingTop:'0px',marginBottom:'0px' }}
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: '280px' }}
              navbarScroll
            >
              <Nav.Link className='navlink ' href="/Home">Home</Nav.Link>
              <NavDropdown title="Our Team" id="navbarScrollingDropdown">
                <NavDropdown.Item className="DropItems" href="Dr_kalyan_chakravarthy_Koganti">Dr Kalyan Chakravarthy Koganti</NavDropdown.Item>
                <NavDropdown.Item className="DropItems" href="Dr_Bharathi">
                 Dr Nella Bharathi
                </NavDropdown.Item> 
              </NavDropdown>
              <Nav.Link className='navlink' href="#action2">Academics</Nav.Link>
              <Nav.Link className='navlink' href="#action2">Facilities</Nav.Link>
              <NavDropdown title="Departments" id="navbarScrollingDropdown">
                <NavDropdown.Item className="DropItems" href="#action3">Departments</NavDropdown.Item>
                <NavDropdown.Item className="DropItems" href="#action4">
                  link1
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item className="DropItems" href="#action5">
                  link2
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Media" id="navbarScrollingDropdown">
                <NavDropdown.Item className="DropItems" href="#action3">link1</NavDropdown.Item>
                <NavDropdown.Item className="DropItems" href="#action4">
                 link2
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item href="#action5">
                  link3
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className='navlink' href="#action2">Gallery</Nav.Link>
              <Nav.Link className='navlink' href="#" >
                Careers
              </Nav.Link>
              <Nav.Link className='navlink' href="#action2">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      {/* <header id="header" class="header">
            <meta name="google-site-verification" content="4rJRejrVqwHVCzwPD2amG6C6JHamDTfr3TjmDc5c12o"/>
            <div class="header-top bg-black-333 sm-text-center border-top-theme-color-3px p-0">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="widget no-border m-0">
                                <ul class="list-inline xs-text-center text-white mt-5">
                                    <li class="m-0"><a href="tel:9040108108" class="text-white"><i class="fa fa-phone text-theme-colored"></i> 9040-108-108</a></li>
                                    <li class="m-0"><a href="mailto:info@shrihospital.com" class="text-white"><i class="fa fa-envelope-o text-theme-colored"></i>
                                        info@shrihospital.com</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <center>
                                <a href="tel:08632213999" class="image01">
                                    <h5 class="covid">Helpline :<span> 0863-2213-999 </span> </h5>
                                </a>
                            </center>
                        </div>

                          <div class="col-sm-3">
                            <center>
                                <a href="tel:9040108108" class="image01">
                                    <h5 class="covid"> Appointment  :<span> 9040108108 </span> </h5>
                                </a>
                            </center>
                        </div>                      
                    </div>
                </div>
            </div>
      </header>
            <div class="header-nav">
                <div class="header-nav-wrapper navbar-scrolltofixed bg-white" style={{zIndex:'auto', position:'static',top: 'auto'}}>
                    <div class="container">
                        <nav id="menuzord-right" class="menuzord default menuzord-responsive"><a href="javascript:void(0)" class="showhide" style={{display: 'block'}}></a>
                            <a class="menuzord-brand pull-left flip xs-pull-center mt-20" href="index.html">
                                <img src="images/logo-wide.png" alt="#"/>
                            </a>
                            <ul class="menuzord-menu menuzord-right menuzord-indented scrollable" style={{maxHeight:'400px', display:'none'}}>
                                <li class="active"><a href="index.html">Home</a></li>
                               
								
								
                                <li class=""><a href="team.html">Our Team</a></li>
                                <li class=""><a href="academics.html">Academics</a></li>
                                <li class=""><a href="facilities.html">Facilities</a></li>
                                <li class=""><a href="#">Departments<span class="indicator"><i class="fa fa-angle-down"></i></span></a>
                                   <ul class="dropdown" style={{right:'auto', display: 'none'}}>
                                        <li><a href="sepsis-critical-care.html">sepsis-critical-care </a></li>
                                       
                                        <li><a href="GASTROENTEROLOGY &amp; HEPATOLOGY.html"> Medical Gastroenterology </a></li>
                                        <li><a href="#">Surgical Gastroenterology</a></li>
										
                                                <li><a href="cardiology.html">Cardiology</a></li>
                                        <li><a href="#"> Cardiothoracic </a></li>
                                        <li><a href="neuro-sciences.html">Neurology</a></li>
										
										
                                                <li><a href="neuro-sciences.html">Neurosurgery</a></li>
                                        <li><a href="urology.html"> Urology </a></li>
                                        <li><a href="#">Nephrology</a></li>
										
										
										
										
										
										
										
										
                                  
                                                <li><a href="#">Plastic surgery</a></li>
                                        <li><a href="#"> General Medicine  </a></li>
                                        <li><a href="generalsurgery.html">General surgery</a></li>
										
										
                                                <li><a href="orthopaedics.html">Orthopedics</a></li>
                                        <li><a href="#"> Polytrauma  </a></li>
                                       
												
										
                                    </ul>
                                </li>
                                <li class=""><a href="#">Media<span class="indicator"><i class="fa fa-angle-down"></i></span></a>
                                    <ul class="dropdown" style={{right:'auto', display: 'none'}}>
                                        <li><a href="#">News</a></li>
                                        <li><a href="videos.html">Videos</a></li>
                                        <li><a href="testimonials.html">Testimonials</a></li>
                                    </ul>
                                </li>
                                <li class=""><a href="gallery.html">Gallery</a></li>
                                <li class=""><a href="careers.html">Careers</a></li>
                                <li class=""><a href="contact.html">Contact</a></li>
                            <li class="scrollable-fix"></li></ul>
                        </nav>
                    </div>
                </div><div style={{display: 'none', width: '1263.33px', height: '102.562px',float: 'none'}}></div>
            </div> */}


      <nav>
        <div className='iconss' sticky="top">
          <div className='row'>
            <div className='emergencyCalls' >
              {/* <a href="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></a> */}

              {/* <a href=''><i class="bi bi-phone-flip">Emergency Call</i></a> */}
              <div className="dropdown emergency" >
                <button className="dropbtn" style={{}}><b>Emergency Call</b></button>
                <div className="dropdown-content emergencyContent " >
                  <a href="tel:9000 100 108" >Dr Kalyan Chakravarthy Koganti</a>
                  <a href="tel:70326 48222" style={{ marginLeft: '-100px' }}>Dr. Bharathi Nelluri</a>

                </div>
              </div>
              <div className="dropdown appointment" >
                <button className="dropbtn1" style={{ borderColor: 'white', color: 'rgb(0, 0, 96)' }}><b>Appointment</b></button>
                <div className="dropdown-content Appointment" >
                  <a href="tel:0863 226 5559">Dr.Kalyan Chakravarthy Koganti</a>
                  <a href="tel:0863 226 5559" style={{ marginLeft: '-100px' }}>Dr.Bharathi Nelluri</a>

                </div>
              </div>
              <div className=' text-center mt-2 insuranceBlock' >
                {/* <Link to='https://www.facebook.com/helphospitalguntur' title='Facebook'><i className="bi bi-facebook"></i></Link>
              <Link to='https://www.linkedin.com/in/dr-kalyan-chakravarthy-koganti-4b6ab0232?originalSubdomain=in' title='LinkedIn'><i className="bi bi-linkedin"></i></Link>
              <Link to='https://www.youtube.com/@kalyankoganti/about' title='Youtube'><i className="bi bi-youtube"></i></Link>
              <Link to='https://www.instagram.com/helphospital_guntur/' title='Instagram' ><i className="bi bi-instagram"></i></Link> */}
                <img src='insu6.webp' className='insuranceLogo'></img>
                <div>
                <a href="/Insurance_Available_In_Hospital" style={{ textDecoration: 'none', }}><p className="InsuranceText text-center" style={{ textDecoration: 'none', }}>All INSURANCES AVAILABLE</p></a>
                </div>
              </div>
              {/*            
               <a href="tel:9000 100 108" className='EMERGENCY'><i class="bi bi-phone-flip"></i> 9000 100 108</a>
                  <a href="tel:70326 48222" className='EMERGENCY' style={{ }}><i class="bi bi-phone-flip"></i> 70326 48222</a>
               */}
            </div>
          </div>
        </div>
        <div className="wrapper" sticky="top" style={{ backgroundColor: 'white' }} >
          <div className="logo"><Link to="#"><img src='nabh-logo-E59469F2F9-seeklogo.com.png' alt='' style={{ width: '60px', height: '60px' }}></img></Link></div>
          <div className="logo"><Link to="#"><img src='Help LOGO (1).png' alt='' style={{ width: '200px', height: '90px' }}></img></Link></div>

          <input type="radio" name="slider" placeholder='null' title='menu' id="menu-btn" />
          <input type="radio" name="slider" placeholder='null' title='close' id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" style={{ color: 'black', width: '20px' }} className="btn close-btn"><i className="bi bi-x-circle"></i></label>
            <li><Link to="/Home" onClick={() => sethome(true)} style={{ color: 'black' }}>Home</Link></li>
            <li>
              <Link to="#" className="desktop-item" onClick={() => setabout(true)} style={{ color: 'black' }}>About</Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item" style={{ color: 'black' }}>About</label>
              <ul className="drop-menu">
                <li><Link to="Dr_Kalyan_Chakravarthy_Koganti" onClick={() => setkalyan(true)}>Dr.Kalyan Chakravarthy Koganti.</Link></li>
                <li><Link to="Dr_Bharathi" onClick={() => setbharathi(true)}>Dr.Nelluri Bharathi.</Link></li>

              </ul>
            </li>
            {/* <li><Link to="#" style={{ color: 'black' }}>Gallery</Link></li> */}

            <li>
              <Link href="#" className="desktop-items" style={{ color: 'black' }}>Gallery</Link>
              <input type="checkbox" id="showDrops" />
              <label htmlFor="showDrops" className="mobile-item" style={{ color: 'black' }}>Gallery</label>
              <ul className="drop-menus">
                <li><Link to="Images" onClick={() => setImages(true)} >Images</Link></li>
                <li><Link to="Videos" onClick={() => setVideos(true)} >Videos</Link></li>

              </ul>
            </li>
            <li><Link to="Blogs" style={{ color: 'black'}} onClick={() => setBlogs(true)} >Blogs</Link></li>
            <li>
              <Link to="#" className="desktop-item" style={{ color: 'black' }}>Specialities</Link>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item" style={{ color: 'black' }} >Specialities</label>
              <div className="mega-box">
                <div className="content">

                  <div className="row">
                    {/* <header>Design Services</header> */}
                    <ul className="mega-links">
                      <li><Link to="Any_Time_Monitoring" onClick={() => setatm(true)}>ATM</Link></li>
                      <li><Link to="BEST_CENTRE_FOR_INFECTIOUS_DISEASES">CID</Link></li>
                      <li><Link to="/Best_Diabetes_Treatment_InGuntur" onClick={() => setdibts(true)}>Diabetes</Link></li>
                      <li><Link to="General_Medicine" onClick={() => setglmds(true)}>General Medicine</Link></li>
                      <li><Link to="Best_Fever_hospital_inGuntur" onClick={() => setLinks(true)}>Fever</Link></li>
                      <li><Link to="Best_High_BP_Treatment_in_Guntur" onClick={() => setbp(true)}>Blood Pressure</Link></li>

                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Email Services</header> */}
                    <ul className="mega-links">
                      {/* <li><a href="SEPSIS_CRITICAL_CARE">SEPSIS & CRITICAL CARE</a></li> */}
                      <li><Link to="Best_Gynecology_Treatment_In_Guntur" onClick={() => setgyncts(true)} >Gynecology</Link></li>


                      <li><Link to="Best_Obstetrics_Treatment_In_Guntur" onClick={() => setobstcs(true)}>Obstetrics</Link></li>
                      <li><Link to="Best_LAPAROSCOPY_Treatement_in_Guntur" onClick={() => setLPY(true)}>Laparoscopy</Link></li>

                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Security services</header> */}
                    <ul className="mega-links">
                      <li><Link to="Birth_help" onClick={() => setbh(true)}>Birth Help</Link></li>
                      <li><Link to="Best_Pcos_pcod_Treatment_in_guntur" onClick={() => setpspd(true)}>PCOS/PCOD</Link></li>
                      <li><Link to="Best_Endometriosis_Treatment_In_Guntur" onClick={() => setendo(true)}> Endometriosis</Link></li>
                      <li><Link to="IVF_Fertility_Treatment" onClick={() => setivf(true)}>IVF Treatment</Link></li>


                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li><Link to="Contact" style={{ color: 'black' }} onClick={() => setcontact(true)}>Contact</Link></li>
            <li><Link to="Insurance_Available_In_Hospital" style={{ color: 'black' }} onClick={() => setinsurance(true)}>Insurance</Link></li>
            {/* <li ><Link to="#" ><button className="btn" onClick={handleShow} style={{ color: 'white', border: 'none', padding: '15px 32px', textAlign: 'center', textDecoration: 'none', display: ' inline-block', fontSize: '16px', margin: '4px 2px', backgroundColor: 'rgb(245, 41, 41)' }}>Book Appointment</button></Link></li> */}
            <li>
              <Link href="#" className="desktop-itemed" style={{ color: 'white', backgroundColor: 'rgb(232, 23, 23)' }}>Book Appointment</Link>
              <input type="checkbox" id="showDroped" />
              <label htmlFor="showDroped" className="mobile-item" style={{ color: 'black' }}>Book Appointment</label>
              <ul className="drop-menued" style={{ marginLeft: '-120px', width: '400px' }}>
                <li><Link to="tel:9000 100 108" style={{}}>Dr.Kalyan Chakravarthy Koganti</Link></li>
                <li><Link to="tel:70326 48222">Dr.Bharathi Nelluri</Link></li>

              </ul>
            </li>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn" style={{ color: 'black' }}><i className="bi bi-list" style={{ blockSize: '100px' }} ></i></label>
        </div>
      </nav>

      <Modal style={{ marginTop: '50px' }} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className='text-center' style={{ color: 'rgb(249, 60, 60)', textAlign: 'center' }}>Book An Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Name} onChange={changeHandler} className="form-control" placeholder="Your Name" name="Name" required="Username is Required..." message="Username must be atmost 30 characters long..." style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="email" value={Email} onChange={changeHandler} className="form-control" placeholder="Your Email Address" name="Email" id="email" required="Email is required" data-error="Please enter your email" fdprocessedid="p2hd6j" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Number} onChange={changeHandler} className="form-control" name="Number" placeholder="Phone Number" id="phone_number" required="Number is Required" data-error="Please enter your number" fdprocessedid="cf7ig" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <input type="text" value={Subject} onChange={changeHandler} className="form-control" name="Subject" placeholder="Subject" id="msg_subject" required="Subject is Required" data-error="Please enter your subject" fdprocessedid="4mor3e" style={{ padding: '10px' }} />
              <div className="help-block with-errors"></div>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >

              <textarea name="Message" value={Message} onChange={changeHandler} className="form-control" id="message" cols="30" rows="3" placeholder="Type Your Message Here" required="Message is required" data-error="Write your message"></textarea>
              <div className="help-block with-errors"></div>
            </Form.Group>
            <button type="submit" className="btn " name="contact" style={{ backgroundColor: "rgb(249, 60, 60)", color: "whitesmoke" }} >Send Message</button>
            <div id="msgSubmit" className="h3 text-center hidden"></div>
            <div className="clearfix"></div>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default Header