import React from 'react'

const Postnatal_Care = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1 style={{ textTransform: 'uppercase' }}>POSTNATAL CARE</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>
            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        {/* <h1 style={{ textAlign: 'center', fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#9932CC', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Postnatal Care</span>
                        </h1> */}
                        <p style={{textAlign:'justify'}}>Our Postnatal  program is to support you further for your complete recovery. Our first month program will include sending you daily messages for one week which will help you for the whole month.</p>
                        <p style={{textAlign:'justify'}}>Firstly, we would like to <b>congratulate you on your delivery </b> and we are happy that we are also a part of your pregnancy journey.</p>
                        <p style={{textAlign:'justify'}}>As you just delivered & you have undergone a stressful process, you as a mother require optimal rest, good diet, peaceful mind & energetic body.</p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Tip for day 1</span>
                        </h4 >


                        <p style={{textAlign:'justify'}}>Immediately after your first month of delivery you tend to have mood swings, depressed mind, anxiety episodes & unwanted fears.</p>
                        <p style={{textAlign:'justify'}}>To overcome this, try to be in a calm & peaceful environment, have positive thoughts, keep yourself engaged in doing your favorite things.</p>
                      
                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '300px', borderRadius: '15px', width: '350px', boxShadow: 'grey' }} id="ServicesImG" src="postnatal-care.jpg" alt=''></img>
                    </div>
                </div>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Tip for day 2 is about diet</span>
                </h4 >
                <p style={{textAlign:'justify'}}>Plan your day according to your baby requirements.</p>
                        <p style={{textAlign:'justify'}}>Try to have at least 7-8 hrs of sleep</p>
                        <p style={{textAlign:'justify'}}>Practice daily 15 mins of meditation, deep breathing exercises, upper body exercises which will all you have to engage for 30-40 mins</p>
                        <p style={{textAlign:'justify'}}>Daily bath twice a day.</p>
                <ol>
                    <li> Follow the same protocol as you followed during pregnancy i.e., 3 main meals & 3-4 snack breaks, take laxative syrups, high fiber diet, plenty of oral fluids</li>
                    <li> Don’t restrict your food intake as balanced diet is very much required for your recovery & for lactation</li>
                    <li> Your diet should include high protein, low carbs & high fiber foods like vegetables & fruits</li>
                    <li> Plenty of oral fluids like water, buttermilk, coconut water occasionally, electrolyte rich water like ORS</li>

                </ol>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Tip for day 3 is about Breast Care</span>
                </h4 >
                <ol>
                    <li> As you are lactating breast care is very important during this period</li>
                    <li> Selection of proper position for lactation is important for your proper bonding with the baby & to avoid back ache</li>
                    <li> So preferably sitting upright position is the good position</li>
                    <li> Avoid bending forward while breastfeeding</li>
                    <li> Keep baby at the level of your breast by keeping pillows</li>
                    <li> Always breastfeed from both sides when you feed the baby</li>
                    <li> Check your breast every day & keep them soft, whenever you feel like heaviness in breast express milk immediately</li>
                    <li> Clean your breast with clear water before & after every breast feed</li>
                    <li> Don’t let the milk get dry, which will cause nipple cracks.</li>
                    <li> To avoid nipple cracks proper latching, avoid dryness by applying moisturizers & clean with clear water your sanitary pads 3-4 times a day</li>

                </ol>


                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Tip for day 4 is about Wound Care</span>
                </h4 >

                <ol>
                    <li> For wound healing that requires proper hygienic environment & proper cleaning of wound, do sitz bath</li>
                    <li> As you undergone delivery process your pelvic muscles got stressed, so to bring them back to their original strength do daily Kegel’s exercises</li>
                    <li> Empty your bladder immediately whenever you feel fullness.</li>
                    <li>  Long-time postponement of urination will lead to urinary infections </li>

                </ol>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>Tip for day 5 is about Medication</span>
                </h4 >

                <ol>
                    <li>  Take daily medications as doctor advised</li>
                    <li>  Don’t take self-medications as you are breastfeeding</li>
                    <li>  Daily monitor your blood pressure levels inform doctor if BP greater than normal</li>
                    <li>  Whenever you feel like tired/exhausted check your temperature, pulse & BP</li>
                    <li>  Your pregnancy changes like swelling of legs, facial puffiness & abdominal loosening will take some more time to  subside</li>
                    
                </ol>

                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>5.  Your pregnancy changes like swelling of legs, facial puffiness & abdominal loosening will take some more time to  subside</span>
                </h4 >

                <ol>
                    <li> Increase in BP greater than 130/90 mmHg</li>
                    <li> Headache</li>
                    <li> Blurring of vision</li>
                    <li> Epigastric Pain</li>
                    <li> Decreased urine output</li>
                    <li> Calf muscle pain</li>
                    <li> Fever with chills</li>
                    <li> Abnormal discharge from your wound</li>
                    <li> Increase redness & swelling near your wound area</li>
                    <li> Breast heaviness & redness</li>
                    <li> Abnormal heavy bleeding.</li>
                 
                </ol>

              <p><b>We wish these tips will help you for your faster & happy recovery.</b></p>
              
            </div >
        </>
    )
}

export default Postnatal_Care