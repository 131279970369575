import React from 'react'

const FLU_VACCINE_DURING_PREGNANCY = () => {
    return (
        <>
            <div style={{ height: '120px' }}></div>
            <div className="container-fluid strips " id="strip" style={{ marginTop: '80px', display: 'flex', backgroundColor: "rgb(245, 41, 41)", }}>
                <div className="col text-center strip1" style={{ color: 'white' }}>
                    <div className="row mt-4" >
                        <h1>FLU VACCINE DURING PREGNANCY</h1>
                    </div>
                    <ul className="container" style={{ listStyle: 'none', textAlign: 'center' }}>
                        <li ><a href="/Home">Home</a></li>

                    </ul>
                </div>
            </div>


            <div className="container mt-5 mb-5 services" data-aos="zoom-in-right" data-aos-duration="3000">
                <div className="row">
                    <div className="col-lg-7 mb-2">
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>Is it safe to get a flu shot during pregnancy?</span>
                        </h4>
                        <p style={{ textAlign: 'justify' }}>
                            Yes, it's safe to get a flu shot during pregnancy. In fact, the Centers for Disease Control and Prevention and the American College of Obstetricians and Gynaecologists recommend that anyone who is pregnant during flu season get a flu shot, regardless of the trimester.
                        </p>
                        <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                            <span style={{ color: "rgb(245, 41, 41)" }}>A flu shot during pregnancy can help:</span>
                        </h4>

                        <ul style={{ listStyleType: 'none' }}>
                            <li>a)	Prevent the flu and other health problems</li>
                            <li>b)	Prevent potential fetal health problems due to the flu</li>
                            <li>c)	Protect a baby after birth</li>
                        </ul>
                        <p style={{ textAlign: 'justify' }}>
                            Pregnant people who get a flu vaccine also are helping to protect their babies from flu illness and flu related hospitalizations for the first several months after their birth, when they are too young to get vaccinated.
                        </p>



                    </div>
                    <div style={{ textAlign: 'center' }} className="col-lg-5 servicesimg">
                        <img className="mt-5 img-thumbnail shadow-lg p-2 mb-5 bg-body " style={{ height: '250px', borderRadius: '15px', width: '80%', boxShadow: 'grey' }} id="ServicesImG" src='Flu-vaccine.png' alt=''></img>
                    </div>
                </div>

                <p style={{ textAlign: 'justify' }}>
                    •	Infants are at a higher risk of severe flu symptoms. But a flu vaccine can't be given until a baby is 6 months old. The antibodies that develop from a flu shot during pregnancy pass through the placenta. They also go through breast milk if you're breastfeeding. These antibodies help protect your baby from the flu after birth.
                </p>

                <p style={{ textAlign: 'justify' }}>
                    When you get vaccinated, request the flu shot — not the nasal spray vaccine. The flu shot is made from a virus that is not active. That makes it safe during any stage of pregnancy. The nasal spray vaccine isn't recommended for use during pregnancy.
                </p>
                <h4 style={{ fontSize: '26px !important', fontFamily: 'Roboto, sans-serif', color: '#b066d4', fontWeight: 'bold', marginBottom: '15px' }}>
                    <span style={{ color: "rgb(245, 41, 41)" }}>When is the best time to take flu vaccine during pregnancy?</span>
                </h4>

                <p style={{ textAlign: 'justify' }}>
                    Influenza vaccine can be given during any trimester of pregnancy. September and October are generally good times to be vaccinated each year. Earlier vaccination (e.g., in July or August) can be considered for people who are in the third trimester of pregnancy during those months.
                </p>
            </div>
        </>
    )
}

export default FLU_VACCINE_DURING_PREGNANCY